import React from "react";
import { View } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utilities/Constants";
import Storage from "../../utilities/Storage";
import COLORS from "../../utilities/Colors";
import { InputDropdown, SearchBar } from "../../components/Input";
import Table from "../../components/Table";
import { PrimaryButton } from "../../components/Button";

export default () => {
    const navigate = useNavigate();
    const columns = [
        { value: 0, label: 'Kode', key: 'kode', flex: 1 },
        { value: 1, label: 'Nama', key: 'name', flex: 2  },
        { value: 2, label: 'Alamat', key: 'address', flex: 2  },
    ];
    const [dealers, setDealers] = React.useState([]);
    const [listDealers, setListDealers] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [selectedCategory, setSelectedCategory] = React.useState(columns[0]);

    const fetchDealers = async (signal) => {
        const token = (await Storage.get()).token;
        fetch(`${BASE_URL}/dealers`, {
            signal: signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setDealers(responseJSON.data);
        });
    };
    const filterDealers = () => {
        const list = dealers.filter(dealer => {
            let status = true;
            if (searchQuery !== '') {
                status = dealer[selectedCategory.key].toLowerCase().includes(searchQuery.toLowerCase())
            }
            return status
        });
        setListDealers(list);
    }
    React.useEffect(async () => {
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchDealers(signal);
        return () => controller.abort();
    }, []);
    React.useEffect(() => {
        filterDealers();
    }, [dealers, searchQuery]);
    return (
        <View style={{ flex: 1 }}>
            <View style={{ width: '100%', flexDirection: 'row', marginBottom: 5 }}>
                <InputDropdown
                    title={selectedCategory.label}
                    data={columns}
                    onSelect={setSelectedCategory}
                />
                <SearchBar
                    searchQuery={searchQuery}
                    onChangeText={setSearchQuery}
                    placeholder={'Cari di Dealers'}
                    containerStyle={{ marginLeft: 5 }}
                />
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                <PrimaryButton
                    title={'TAMBAH DEALER'}
                    icon="plus"
                    iconType="ant-design"
                    iconSize={14}
                    iconPosition="left"
                    iconColor={COLORS.WHITE}
                    fontSize={12}
                    onPress={() => navigate('/dealers/add')}
                    containerStyle={{ flex: 1 }}
                    color={COLORS.RED}
                />
            </View>
            <Table
                data={listDealers}
                columns={columns}
                to="dealers"
            />
        </View>
    )
}