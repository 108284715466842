import React from "react";
import {Image, View} from "react-native-web";
import { BASE_URL } from "../../utilities/Constants";
import { Divider, Text } from "react-native-elements";
import Storage from "../../utilities/Storage";
import Swal from "sweetalert2";
import { IconButton, PrimaryButton, SecondaryButton } from "../../components/Button";
import COLORS from "../../utilities/Colors";
import { InputText } from "../../components/Input";
import { createFeatures } from "./Functions";

export default (props) => {
    const { unitID, styles, update, data, onFinish } = props;
    const [features, setFeatures] = React.useState([]);
    const [saved, setSaved] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const fetchFeatures = async (signal) => {
        setLoading(true);
        const { token } = await Storage.get();
        const { success, features } = await fetch(
            `${BASE_URL}/motors/${unitID}/features`, {
                signal: signal,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        ).then(async response => await response.json());
        if (!success) return;
        const data = [];
        const metadata = { type: 'image/jpeg' };
        for (const feature of features) {
            const res = await fetch(`${BASE_URL}/${feature.file}`);
            const blob = await res.blob();
            const fileName = feature.file ? `${feature.file.split('/')[1]}.jpg` : '';
            const file = new File([blob], fileName, metadata);
            data.push({
                header: feature.header,
                value: '',
                file: file,
                detail: feature.detail,
                img: `${BASE_URL}/${feature.file}`
            });
        }
        setFeatures(data);
        setLoading(false);
    }
    const finish = async () => {
        for (const feature of features) {
            if (!feature.header) {
                await Swal.fire({
                    title: 'Gagal input fitur motor, ada nama yang kosong',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            }
            if (!feature.detail) {
                await Swal.fire({
                    title: 'Gagal input fitur motor, ada kode yang kosong',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            }
        }
        if (update) {
            setLoading(true);
            await createFeatures(unitID, features)
                .then(() => setLoading(false));
        } else {
            setSaved(true);
        }
        onFinish(features);
    }

    const addFeature = () => {
        setFeatures(prevState => ([...prevState, { header: '', detail: '', value: '', file: undefined, img: '' }]));
    }
    const deleteFeature = (index) => {
        const newFeature = features.filter((_, i) => i !== index);
        setFeatures(newFeature);
    }
    const setItemText = (index, value, attr) => {
        let items = [...features];
        let item = { ...items[index] };
        item[attr] = value;
        items[index] = item;
        setFeatures(items);
    }
    const setItemImg = async (index, e) => {
        let items = [...features];
        let item = { ...items[index] };
        item.value = e.target.value;
        item.file = e.target.files[0];
        item.img = URL.createObjectURL(e.target.files[0])
        items[index] = item;
        setFeatures(items);
    }

    React.useEffect(async () => {
        if (!update) return;
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchFeatures(signal);
        return () => controller.abort();
    }, []);

    React.useEffect(() => {
        setFeatures(data);
    }, [data]);

    return (
        <View styles={{ flex: 1 }}>
            <View style={{ ...styles.infoRow, justifyContent: 'space-between' }}>
                <Text style={styles.infoTitle}>
                    Fitur Utama
                </Text>
                <Text style={styles.smallInfo}>
                    * save dahulu sebelum berpindah tab
                </Text>
            </View>
            {features.map((feature, i) => (
                <View key={i}>
                    <Divider style={styles.divider}/>
                    <View style={{ ...styles.infoRow, justifyContent: 'space-between' }}>
                        <View style={{ flex: 1, marginRight: 10, paddingHorizontal: 10 }}>
                            <View style={styles.infoRow}>
                                <Image
                                    source={feature.img}
                                    style={{
                                        resizeMode: 'contain',
                                        width: '100%',
                                        minHeight: feature.img ? 200 : 0,
                                        marginBottom: 20
                                    }}/>
                                <IconButton
                                    icon="trash"
                                    iconType="font-awesome"
                                    iconColor={COLORS.RED}
                                    iconSize={28}
                                    onPress={() => deleteFeature(i)}
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    // value={feature.value}
                                    onChange={e => setItemImg(i, e)}/>
                            </View>
                            <InputText
                                placeholder="Header"
                                value={feature.header}
                                onChangeText={text => setItemText(i, text, 'header')}
                                containerStyle={{marginBottom: 5}}
                            />
                            <InputText
                                placeholder="Detail"
                                value={feature.detail}
                                onChangeText={text => setItemText(i, text, 'detail')}
                            />
                        </View>
                    </View>
                </View>
            ))}
            {saved?
                <Text style={{ fontSize: 12, color: COLORS.GREEN, paddingHorizontal:10 }}>
                    Data tersimpan
                </Text> : <></>
            }
            <View style={styles.actionContainer}>
                <PrimaryButton
                    title={'SAVE'}
                    fontSize={12}
                    onPress={finish}
                    loading={loading}
                    containerStyle={{ flex: 1, marginRight: 5 }}
                    buttonStyle={styles.actionButton}
                />
                <SecondaryButton
                    icon="plus"
                    iconType="ant-design"
                    iconSize={14}
                    iconColor={COLORS.BLUE}
                    onPress={addFeature}
                    buttonStyle={styles.secondaryActionButton}
                />
            </View>
        </View>
    )
}
