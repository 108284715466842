import React from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, setMediaQuery } from "../../utilities/Constants";
import Swal from "sweetalert2";
import Storage from "../../utilities/Storage";
import { View } from "react-native-web";
import { Text } from "react-native";
import { InputText } from "../../components/Input";
import { PrimaryButton } from "../../components/Button";

export default () => {
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const [name, setName] = React.useState({value: '', error: ''});
    const [address, setAddress] = React.useState({value: '', error: ''});
    const [kode, setKode] = React.useState({value: '', error: ''});

    const handleSubmit = async () => {
        const token = (await Storage.get()).token;
        await fetch(`${BASE_URL}/dealers/create`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                name: name.value,
                address: address.value,
                kode: kode.value,
            })
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                const message = responseJSON.message;
                setKode({ value: kode.value, error: message.kode ? message.kode[0] : '' });
                setName({ value: name.value, error: message.name ? message.name[0] : '' });
                setAddress({ value: address.value, error: message.address ? message.address[0] : '' });
                return;
            }
            await Swal.fire({
                title: 'Berhasil Register Dealer',
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            setKode({ value: '', error: '' });
            setName({ value: '', error: '' });
            setAddress({ value: '', error: '' });
        });
    }

    return (
        <View style={{ flex: 1 }}>
            <View style={{ maxWidth: 640 }}>
                <Text style={{ fontSize: 18, fontWeight: '500', marginBottom: 15 }}>
                    Register Dealer
                </Text>
                <InputText
                    placeholder='Nama'
                    error={name.error}
                    value={name.value}
                    onChangeText={value => setName({ value: value, error: '' })}
                    containerStyle={styles.inputContainer}
                />
                <InputText
                    placeholder='Alamat'
                    error={address.error}
                    value={address.value}
                    onChangeText={value => setAddress({ value: value, error: '' })}
                    containerStyle={styles.inputContainer}
                />
                <InputText
                    numeric
                    placeholder='Kode'
                    error={kode.error}
                    value={kode.value}
                    onChangeText={value => setKode({ value: value, error: '' })}
                    containerStyle={styles.inputContainer}
                />
                <View style={styles.actionContainer}>
                    <PrimaryButton
                        title={'REGISTER'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={() => handleSubmit()}
                        containerStyle={styles.buttonContainer}
                        buttonStyle={styles.button}
                    />
                </View>
            </View>
        </View>
    )
}

const setStyles = (mediaQuery) => ({
    inputContainer: {
        marginTop: 10
    },
    actionContainer: {
        maxWidth: 720,
        paddingVertical: 10,
        flexDirection: 'row',
        justifyContent: 'end'
    },
    buttonContainer: {
        marginLeft: 5
    },
    button: {}
});