import React from "react";
import { Text } from "react-native";
import { Divider } from "react-native-elements";
import { Image, View } from "react-native-web";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { IconButton, PrimaryButton } from "../../components/Button";
import COLORS from "../../utilities/Colors";
import { BASE_URL } from "../../utilities/Constants";
import Storage from "../../utilities/Storage";

export default () => {
    const [creditFile, setCreditFile] = React.useState({ value: '', file: undefined, error: '' });
    const [creditImg, setCreditImg] = React.useState([{ value: '', file: undefined, img: '', error: '' }]);
    const [credit, setCredit] = React.useState([]);
    const [affected, setAffected] = React.useState([]);
    const fetchCredit = async () => {
        const { token } = await Storage.get();
        fetch(`${BASE_URL}/motors/creditImage`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success || !responseJSON.data) return;
            const imgs = [];
            for (const path of responseJSON.data) {
                if (!path) continue;
                imgs.push({
                    value: '',
                    file: undefined,
                    img: `${BASE_URL}/${path}`,
                    error: ''
                });
            }
            setCreditImg(imgs);
        })
    }
    const readCreditFile = () => {
        if (!creditFile.file) return;
        const reader = new FileReader();
        reader.onload = async (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = await XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            let total = [];
            for (const thisSheet of wb.SheetNames) {
                const ws = wb.Sheets[thisSheet];
                let data = XLSX.utils.sheet_to_json(ws, { header: 1, rawNumbers: true, blankrows: false });
                data = data.filter(e => e.length > 0);
                let motors = [];
                let otrs = [];
                let motorNow = 0;
                let motorCount = 0
                const maxKanan = data[data.length - 1].length
                for (let i = 0; i < maxKanan; i++) {
                    if (typeof data[0][i] === 'string') {
                        motorCount++;
                        motors.push(data[0][i]);
                    }
                    if (typeof data[0][i] === 'number') {
                        otrs.push(data[0][i])
                    }
                }
                //mencari kali-kalian
                let x = 0, y = 1, minx = 0, miny = 0, maxx = 0, maxy = data.length - 1;
                while (true) {
                    let header = []
                    if (typeof data[y][x] === 'number' && data[y][x] < 100) {
                        minx = x - 1;
                        miny = y + 1;
                        while (true) {
                            if (typeof data[y][x] !== 'number') {
                                maxx = x - 1;
                                break;
                            }
                            header.push(data[y][x++]);
                        }
                        let credit = [];
                        for (let i = miny; i <= maxy; i++) {
                            let details = [];
                            let dp = '';
                            for (let j = minx; j <= maxx; j++) {
                                if (j === minx) {
                                    dp = data[i][j]
                                } else {
                                    details.push(data[i][j])
                                }
                            }
                            credit.push({
                                dp, details
                            })
                        }
                        total.push({
                            credit,
                            motor: motors[motorNow],
                            otr: otrs[motorNow++],
                            header
                        })
                        minx = maxx + 1;
                        motorCount--;
                        if (motorCount === 0) break;
                    }
                    x++;
                    if (x > maxKanan) {
                        y++;
                        x = minx;
                    }
                    if (y > maxy) {
                        break;
                    }
                }
            }
            setCredit(total);
        }
        reader.readAsBinaryString(creditFile.file);
    }
    const submitTable = async () => {
        const { token } = await Storage.get();
        const formData = new FormData();
        if (credit.length === 0) {
            await Swal.fire({
                title: 'Gagal input credit motor',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        formData.append('credit', JSON.stringify(credit));
        fetch(`${BASE_URL}/motors/create/creditBatch`, {
            method: 'POST',
            mode: 'cors',
            headers: { Authorization: "Bearer " + token },
            body: formData
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                await Swal.fire({
                    title: 'Gagal input credit motor',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            }
            await Swal.fire({
                title: 'Berhasil input credit motor',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            setAffected(responseJSON.affectedMotor);
        });
    }
    const submitImg = async () => {
        const { token } = await Storage.get();
        let success = false;
        await fetch(`${BASE_URL}/motors/clear/credit-image`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}` },
        });
        for (const img of creditImg) {
            if (!img) continue;
            if (img.file.size / 1024 / 1024 > 2) {
                await Swal.fire({
                    title: 'File harus dibawah 2MB',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            }
            const formData = new FormData();
            formData.append('file', img.file);
            const response = await fetch(`${BASE_URL}/motors/create/creditImage`, {
                method: 'POST',
                headers: { Authorization: "Bearer " + token },
                body: formData
            }).then(async response => {
                return await response.json();
            });
            success = response.success;
        }
        await Swal.fire({
            title: `${!success ? 'Gagal' : 'Berhasil'} update brosur`,
            icon: !success ? 'error' : 'success',
            confirmButtonText: 'Ok'
        });
    }

    const onChangeCreditFile = (e) => {
        setCreditFile({
            value: e.target.value,
            file: e.target.files[0],
            error: ''
        });
    }
    const onChangeCreditImg = (e, i) => {
        let items = [...creditImg];
        let item = { ...items[i] };
        item.value = e.target.value;
        item.file = e.target.files[0];
        item.img = URL.createObjectURL(item.file)
        items[i] = item;
        setCreditImg(items);
    }
    const addCreditImg = () => {
        setCreditImg(prevState => ([
            ...prevState, {
                value: '',
                file: undefined,
                img: '',
                error: ''
            }
        ]));
    }
    const deleteImage = (index) => {
        setCreditImg(creditImg.filter((_, i) => i !== index));
    }

    React.useEffect(() => {
        readCreditFile();
    }, [creditFile.file]);

    React.useEffect(async () => {
        await fetchCredit();
    }, []);

    return (
        <View style={{ flex: 1, marginTop: 10 }}>
            <Text style={{ fontSize: 18, fontWeight: '500' }}>
                Input Batch Pricelist
            </Text>
            <View style={styles.row}>
                <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    onChange={onChangeCreditFile}
                />
                <PrimaryButton
                    title='Submit'
                    fontSize={12}
                    onPress={submitTable}
                />
            </View>
            {affected.length <= 0 ? <View/> : (
                <View>
                    <Text style={{ fontSize: 16, fontWeight: '500', marginTop: 10 }}>
                        Motor yang terupdate :
                    </Text>
                    <View style={{ maxWidth: 425, marginTop: 10 }}>
                        {affected.map((item, index) => (
                            <View key={index} style={styles.row}>
                                <Text style={{ flex: 0.5, fontSize: 16 }}>
                                    {item.kode}
                                </Text>
                                <Text style={{ flex: 1, fontSize: 16 }}>
                                    - {item.name}
                                </Text>
                            </View>
                        ))}
                    </View>
                </View>
            )}
            <Divider style={styles.divider}/>
            <View style={styles.row}>
                <View>
                    <Text style={{ fontSize: 18, fontWeight: '500' }}>
                        Gambar Pricelist
                    </Text>
                    <Text style={{ fontSize: 12 }}>
                        * maksimum size file 2MB
                    </Text>
                </View>
                <PrimaryButton
                    icon="plus"
                    iconType="ant-design"
                    iconSize={14}
                    onPress={addCreditImg}
                    buttonStyle={styles.statusButton}
                />
            </View>
            {creditImg.map((img, i) => (
                <View key={i} style={{ ...styles.container, marginTop: 20 }}>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => onChangeCreditImg(e, i)}
                    />
                    {!img.img ? <View/> :
                        <View style={{ flexDirection: 'row' }}>
                            <Image
                                source={{ uri: img.img }}
                                style={{
                                    resizeMode: 'contain',
                                    width: '100%',
                                    minHeight: img.img ? 200 : 0,
                                    marginVertical: 10
                                }}
                            />
                            <IconButton
                                icon="trash"
                                iconType="font-awesome"
                                iconColor={COLORS.RED}
                                iconSize={28}
                                onPress={() => deleteImage(i)}
                            />
                        </View>
                    }
                </View>
            ))}
            <View style={styles.container}>
                <PrimaryButton
                    title='Submit'
                    fontSize={12}
                    onPress={submitImg}
                />
            </View>
        </View>
    )
}

const maxWidth = 640;
const styles = {
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: maxWidth,
        marginTop: 10
    },
    container: {
        maxWidth: maxWidth,
        marginTop: 10
    },
    divider: {
        borderColor: COLORS.GRAY,
        borderBottomWidth: 1,
        maxWidth: maxWidth,
        marginVertical: 10
    },
}