import React from "react";
import { ThemeProvider } from "react-native-elements";
import AppRoutes from "./src/routes";

export default function App() {
    return (
        <ThemeProvider>
            <AppRoutes/>
        </ThemeProvider>
    );
}
