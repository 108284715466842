import React from "react";
import { Text } from "react-native";
import { Switch } from "react-native-elements";
import Select2 from "react-native-select-two";
import { View } from "react-native-web";
import Swal from "sweetalert2";
import { PrimaryButton } from "../../components/Button";
import { InputDropdown, InputText } from "../../components/Input";
import COLORS from "../../utilities/Colors";
import { BASE_URL, setMediaQuery } from "../../utilities/Constants";
import Storage from "../../utilities/Storage";

export default () => {
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const [name, setName] = React.useState({ value: '', error: '' });
    const [email, setEmail] = React.useState({ value: '', error: '' });
    const [phone, setPhone] = React.useState({ value: '', error: '' });
    const [role, setRole] = React.useState({ value: {}, error: '' });
    const [roles, setRoles] = React.useState([]);
    const [dealer, setDealer] = React.useState({ value: [], error: '' });
    const [dealers, setDealers] = React.useState([]);
    const [mainDealer, setMainDealer] = React.useState(false);

    const fetchRoles = async () => {
        const token = (await Storage.get()).token;
        fetch(`${BASE_URL}/users/roles`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setRoles(Object.keys(responseJSON.data).map(key => {
                return { value: +key, label: responseJSON.data[key] };
            }));
        });
    };
    const fetchDealers = async (dealer) => {
        const token = (await Storage.get()).token;
        fetch(`${BASE_URL}/dealers/selection`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setDealers(responseJSON.data.map((item) => ({
                id: item.value,
                name: item.label,
                checked: dealer && dealer.includes(item.value)
            })));
        });
    };
    const handleSubmit = async () => {
        if (dealer.value.length === 0) {
            await Swal.fire({
                title: 'Pilih Minimal 1 Dealer',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        const token = (await Storage.get()).token;
        await fetch(`${BASE_URL}/users/register`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                name: name.value,
                email: email.value,
                phone_number: phone.value,
                role: role.value.value,
                dealer_id: dealer.value,
            })
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                const message = responseJSON.message;
                setEmail({ value: email.value, error: message.email ? message.email[0] : '' });
                setName({ value: name.value, error: message.name ? message.name[0] : '' });
                setPhone({ value: phone.value, error: message.phone_number ? message.phone_number[0] : '' });
                setRole({ value: role.value, error: message.role ? message.role[0] : '' });
                return;
            }
            await Swal.fire({
                title: 'Berhasil Register User',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                setEmail({ value: '', error: '' });
                setName({ value: '', error: '' });
                setPhone({ value: '', error: '' });
                setDealer({ value: '', error: '' });
                setRole({ value: '', error: '' });
            });
        });
    }
    const getRole = () => {
        if (roles.length <= 0 || !role) return
        const role1 = roles.find(r => role.value.value === r.value);
        if (!role1) return;
        return role1.label;
    };

    React.useEffect(async () => {
        await fetchRoles();
        await fetchDealers();
    }, []);

    React.useEffect(() => {
        if (!mainDealer) {
            setDealer({ value: [], error: "" });
        } else {
            setDealer({
                value: dealers.map(dealer => dealer.id),
                error: ""
            })
        }
    }, [mainDealer])

    return (
        <View style={{ flex: 1, maxWidth: 640 }}>
            <Text style={{ fontSize: 18, fontWeight: '500', marginBottom: 15 }}>
                Register User
            </Text>
            <InputDropdown
                title={'Pilih Role'}
                data={roles}
                value={getRole()}
                onSelect={role => {
                    setRole({ value: role, error: '' })
                }}
            />
            <View style={{ flexDirection: 'row', marginVertical: 15, gap: 5 }}>
                <Switch
                    value={mainDealer}
                    onValueChange={value => setMainDealer(value)}
                />
                <Text style={{ fontWeight: '400' }}>
                    Main Dealer
                </Text>
            </View>
            {!mainDealer && (
                <>
                    <Text style={{ fontSize: 12, fontWeight: '500', marginVertical: 5 }}>
                        Dealer
                    </Text>
                    <Select2
                        isSelectSingle={false}
                        showSearchBox={false}
                        style={styles.select2}
                        modalStyle={styles.select2Modal}
                        colorTheme={COLORS.RED}
                        cancelButtonText="Cancel"
                        selectButtonText="OK"
                        searchPlaceHolderText="Cari Dealer"
                        listEmptyTitle="Pilih Dealer"
                        popupTitle="Pilih Dealer"
                        title="Pilih Dealer"
                        data={dealers}
                        onSelect={value => setDealer({ value: value, error: '' })}
                        onRemoveItem={value => setDealer({ value: value, error: '' })}
                    />
                </>
            )}
            <InputText
                placeholder='Nama'
                error={name.error}
                value={name.value}
                onChangeText={value => setName({ value: value, error: '' })}
                containerStyle={styles.inputContainer}
            />
            <InputText
                placeholder='Email'
                error={email.error}
                value={email.value}
                onChangeText={value => setEmail({ value: value, error: '' })}
                containerStyle={styles.inputContainer}
            />
            <InputText
                numeric
                placeholder='Nomor Telepon'
                error={phone.error}
                value={phone.value}
                onChangeText={value => setPhone({ value: value, error: '' })}
                containerStyle={styles.inputContainer}
            />
            <View style={styles.actionContainer}>
                <PrimaryButton
                    title={'REGISTER'}
                    fontSize={mediaQuery.mobileM ? 10 : 12}
                    onPress={() => handleSubmit()}
                    containerStyle={styles.buttonContainer}
                    buttonStyle={styles.button}
                />
            </View>
        </View>
    )
}

const setStyles = (mediaQuery) => ({
    inputContainer: {
        marginTop: 10
    },
    actionContainer: {
        maxWidth: 720, paddingVertical: 10,
        flexDirection: 'row', justifyContent: 'end'
    },
    buttonContainer: {
        marginLeft: 5
    },
    button: {},
    select2: {
        borderRadius: 3, borderWidth: 2,
        borderColor: COLORS.GRAY,
        backgroundColor: COLORS.WHITE
    },
    select2Modal: {
        alignSelf: 'center',
        width: '100%', maxWidth: 640
    }
});