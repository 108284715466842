import React from "react";
import { useMediaQuery } from "react-responsive";

// export const BASE_URL = "http://localhost:8000/api";
export const BASE_URL = "https://api.hondabengkulu.co.id/api";
export const setMediaQuery = () => ({
    mobile: useMediaQuery({ minWidth: 480, maxWidth: 640 }),
    desktop: useMediaQuery({ minWidth: 640 }),
    mobileS: useMediaQuery({ maxWidth: 321 }),
    mobileM: useMediaQuery({ maxWidth: 376 }),
    mobileL: useMediaQuery({ maxWidth: 481 }),
    tablet: useMediaQuery({ maxWidth: 768 }),
    sm: useMediaQuery({ minWidth: 800 }),
    md: useMediaQuery({ minWidth: 920 }),
    xl: useMediaQuery({ minWidth: 1280 })
});
export const waMessages = [
    'Salam Satu Hati, ',
    'Salam Satu Hati, Booking anda diterima',
    'Salam Satu Hati, Booking anda ditolak',
    'Salam Satu Hati, Booking anda ditolak dan silakan booking lagi dengan DP yang lebih besar',
];
export const ROLES = {
    DEALER: [1, 10],
    ADMIN: [70, 74],
    ALL: 75
}
export const BOOKING_STATUS = [
    'Pending',
    'Approved',
    'Reject',
    'Cancel',
    'Add DP'
];
