import React from "react";
import { View } from "react-native-web";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, setMediaQuery } from "../../utilities/Constants";
import Storage from "../../utilities/Storage";
import COLORS from "../../utilities/Colors";
import { InputDropdown, SearchBar } from "../../components/Input";
import Table from "../../components/Table";
import { PrimaryButton } from "../../components/Button";

const ListUsers = () => {
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const categories = [
        { value: 0, label: 'Nama', key: 'name' },
        { value: 1, label: 'Email', key: 'email' },
    ];
    const columns = [
        { key: 'role', label: 'Tipe', flex: 1 },
        { key: 'name', label: 'Nama', flex: 2 },
        { key: 'email', label: 'Email', flex: 2 },
        // { key: 'created_at', label: 'Tanggal Daftar', flex: 1 },
    ]
    const [users, setUsers] = React.useState([]);
    const [listUsers, setListUsers] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [selectedCategory, setSelectedCategory] = React.useState(categories[0]);

    const fetchUsers = async (signal) => {
        const token = (await Storage.get()).token;
        fetch(`${BASE_URL}/users`, {
            method: 'POST',
            signal: signal,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setUsers(responseJSON.users);
        });
    };
    const filterUsers = () => {
        const list = users.filter(user => {
            return user[selectedCategory.key].toLowerCase().includes(searchQuery.toLowerCase())
        });
        setListUsers(list);
    }
    const onPressRow = (user) => {
        navigate(`/users/${user.id}`, { replace: true });
    }
    React.useEffect(async () => {
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchUsers(signal);
        return () => controller.abort();
    }, []);
    React.useEffect(() => {
        filterUsers();
    }, [users, searchQuery]);
    return (
        <View style={{ flex: 1 }}>
            <View style={{ width: '100%', flexDirection: 'row', marginBottom: 5 }}>
                <InputDropdown
                    title={selectedCategory.label}
                    data={categories}
                    onSelect={setSelectedCategory}
                />
                <SearchBar
                    searchQuery={searchQuery}
                    onChangeText={setSearchQuery}
                    placeholder={'Cari di Users'}
                    containerStyle={{ marginLeft: 5 }}
                />
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                <Link to="/users/add" style={{ flex: 1, textDecorationLine: 'none' }}>
                    <PrimaryButton
                        title="Tambah User"
                        icon="plus"
                        iconType="ant-design"
                        iconSize={14}
                        iconPosition="left"
                        iconColor={COLORS.WHITE}
                        fontSize={14}
                        // onPress={() => navigate('/users/add')}
                        containerStyle={{ flex: 1 }}
                        color={COLORS.RED}
                    />
                </Link>
            </View>
            <Table
                data={listUsers}
                columns={columns}
                to="users"
            />
        </View>
    )
}

const setStyles = mediaQuery => ({});

export default ListUsers;