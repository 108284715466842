import { FlatList, ScrollView, TouchableOpacity, View } from "react-native-web";
import React from "react";
import { Text } from "react-native-elements";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { setMediaQuery } from "../utilities/Constants";
import COLORS from "../utilities/Colors";
import moment from "moment";
import { formatDisplayDateTime, formatMoney } from "../utilities/Helpers";
import { Link, NavLink } from "react-router-dom";

const Table = (props) => {
    const { columns, onSelect, height, to, navKey = 'id' } = props;
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const [data, setData] = React.useState(props.data ?? []);
    const [direction, setDirection] = React.useState(undefined);
    const [selectedColumn, setSelectedColumn] = React.useState(undefined);
    const sortTable = (column) => {
        setDirection(direction === "desc" ? "asc" : "desc")
        const desc = direction === "desc"
        const compare = (a, b) => {
            if (desc) return a[column] > b[column] ? 1 : -1
            return b[column] > a[column] ? 1 : -1
        }
        const sortedData = data.sort(compare)
        setSelectedColumn(column)
        setData(sortedData)
    }
    const tableHeader = () => (
        <View style={styles.tableHeader}>
            {columns.map((column, i) => (
                <TouchableOpacity
                    key={i}
                    style={{
                        ...styles.columnHeader,
                        flex: column.flex ?? 1
                    }}
                    onPress={() => sortTable(column)}>
                    <Text style={styles.columnHeaderTxt}>
                        {column.label + " "}
                        {selectedColumn === column &&
                        <MaterialCommunityIcons
                            name={direction === "desc" ? "arrow-down-drop-circle" : "arrow-up-drop-circle"}/>
                        }
                    </Text>
                </TouchableOpacity>
            ))}
        </View>
    )
    const showColumn = (item, column) => {
        if (column.date) return formatDisplayDateTime(item)
        if (column.price) return formatMoney(item)
        return item;
    }
    React.useEffect(() => {
        setData(props.data ?? []);
    }, [props.data])
    return (
        <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            style={{ flex: 1 }}
            contentContainerStyle={{
                flex: 1,
                minWidth: 720
            }}
        >
            <FlatList
                data={data}
                style={{ width: '100%', minHeight: height ?? '80vh' }}
                keyExtractor={(item, index) => index}
                ListHeaderComponent={tableHeader}
                stickyHeaderIndices={[0]}
                renderItem={({ item, index }) => {
                    if (to) {
                        return (
                            <Link to={`/${to}/${item[navKey]}/${item.has_form ? props.extend : ''}`} style={{ flexDirection: 'row', textDecorationLine: 'none' }}>
                                <TouchableOpacity style={styles.tableRow(index)}>
                                    {columns.map((column, i) =>
                                        <Text key={i} style={{
                                            ...styles.columnRowTxt,
                                            flex: column.flex ?? 1,
                                            width: 100
                                        }}>
                                            {showColumn(item[column.key], column)}
                                        </Text>
                                    )}
                                </TouchableOpacity>
                            </Link>
                        )
                    }
                    return (
                        <TouchableOpacity style={styles.tableRow(index)} onPress={onSelect}>
                            {columns.map((column, i) =>
                                <Text key={i} style={{
                                    ...styles.columnRowTxt,
                                    flex: column.flex ?? 1,
                                    width: 100
                                }}>
                                    {showColumn(item[column.key], column)}
                                </Text>
                            )}
                        </TouchableOpacity>
                    )
                }}
            />
        </ScrollView>
    )
}


const setStyles = mediaQuery => ({
    container: {
        flex: 1,
        backgroundColor: COLORS.WHITE,
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 80
    },
    tableHeader: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        backgroundColor: COLORS.RED,
        paddingHorizontal: 10,
        height: 42,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    tableRow: i => ({
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: 10,
        paddingVertical: 10,
        backgroundColor: i % 2 === 1 ? COLORS.DARK_SMOKE : COLORS.WHITE
    }),
    columnHeader: {
        marginHorizontal: 10,
        justifyContent: "center",
        alignItems: "flex-start"
    },
    columnHeaderTxt: {
        color: "white",
        fontWeight: "bold",
    },
    columnRowTxt: {
        marginHorizontal: 10,
        paddingVertical: 5,
        textAlign: "left"
    }
});


export default Table;