const COLORS = {
    RED: '#E81D31',
    GREEN: '#1DE76E',
    BLUE: '#1D96E7',
    WHITE: '#FFFFFF',
    BLACK: '#414245',
    GRAY: '#C1C2C5',
    DARK_SMOKE: '#F0F0F0',
    SMOKE: '#F5F5F5',
};

export default COLORS;