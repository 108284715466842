import moment from "moment";
import numeral from "numeral";
import Storage from "./Storage";

const MONTHS = [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli",
    "Agustus", "September", "Oktober", "November", "Desember"
]

export const headers = async () => {
    const { token } = await Storage.get();
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
}
export const headerAuth = async () => {
    const { token } = await Storage.get();
    return { 'Authorization': `Bearer ${token}` };
}

export const formatDisplayDateShort = (date) => {
    if (!date) return undefined;
    return date ? moment(date).format('DD MMM YYYY') : '-';
};
export const formatDisplayDateTime = (date) => {
    if (!date) return undefined;
    return date ? moment(date).format('DD-MM-YYYY hh:mm') : '-';
};
export const formatDisplayDateLong = (date) => {
    if (!date) return undefined;
    moment.updateLocale('en', { months: MONTHS });
    return date ? moment(date).format('DD MMMM YYYY') : '-';
};
export const formatMoney = (number = 0) => (
    number && !isNaN(number) ? `Rp ${numeral(number).format('0,0')}` : "-"
);
export const formatMoneyShort = (number = 0) => (
    number && !isNaN(number) ? `${numeral(number).format('0,0')}` : "-"
)
export const convertToJson = (csv) => {
    let lines = csv.split("\n");
    let result = [];
    let headers = lines[0].split(",");
    for (let i = 1; i < lines.length; i++) {
        let obj = {};
        let currentline = lines[i].split(",");
        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }
        result.push(obj);
    }
    return result; //JSON
}
export const convertToCSV = (objArray) => {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            if (line !== '') line += ','
            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
}
export const exportCSVFile = (headers, items, fileTitle) => {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    let jsonObject = JSON.stringify(items);

    let csv = convertToCSV(jsonObject);

    let exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        let link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export const sortColumn = (index, sortDirections, COLUMNS, data) => {
    const key = COLUMNS[index].key;
    let list;
    if (sortDirections[key]) {
        list = data.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
    } else {
        list = data.sort((a, b) => (b[key] > a[key]) ? 1 : -1);
    }
    return list;
}