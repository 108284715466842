import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native-web";
import COLORS from "../../utilities/Colors";

export default () => {
    return (
        <View style={styles.container}>
            <ActivityIndicator size="large" color={COLORS.RED} />
        </View>
    )
}

const styles = StyleSheet.create({
   container: {
       flex:1,
       justifyContent: 'center',
       alignItems: 'center'
   }
});