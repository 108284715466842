import React from "react";
import { useParams } from "react-router-dom";
import { BASE_URL, BOOKING_STATUS, setMediaQuery, waMessages } from "../../utilities/Constants";
import Storage from "../../utilities/Storage";
import { Image, TouchableOpacity, View } from "react-native-web";
import Swal from "sweetalert2";
import { Text } from "react-native";
import COLORS from "../../utilities/Colors";
import { formatDisplayDateTime, formatMoney } from "../../utilities/Helpers";
import { Button, Divider } from "react-native-elements";
import { IconButton, PrimaryButton } from "../../components/Button";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import Table from "../../components/Table";
import InfoStyles from "../../utilities/InfoStyles";
import Recommendation from "./Recommendation";

export default () => {
    const mediaQuery = setMediaQuery();
    const styles = InfoStyles(mediaQuery);
    const { id } = useParams();
    const [data, setData] = React.useState({});
    const [ktpImage, setKtpImage] = React.useState({ name: '', img: '' });
    const [kkImage, setKkImage] = React.useState({ name: '', img: '' });
    const [ktpPenjaminImage, setKtpPenjaminImage] = React.useState({ name: '', img: '' });
    const [kkPenjaminImage, setKkPenjaminImage] = React.useState({ name: '', img: '' });
    const [logs, setLogs] = React.useState([]);
    const [selectedTab, setSelectedTab] = React.useState('recommendation');
    const [criteria, setCriteria] = React.useState([]);
    const columns = [
        { key: 'created_at', label: 'Tanggal Ubah', flex: 1, date: true },
        { key: 'detail', label: 'Detail', flex: 2 },
        { key: 'status', label: 'Status', flex: 1 },
    ];

    const openWA = (num) => {
        window.open(`https://api.whatsapp.com/send?phone=${num}&text=${waMessages[0]}`);
    }

    const fetchBooking = async () => {
        const token = (await Storage.get()).token;
        fetch(`${BASE_URL}/bookings/${id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setData(responseJSON.data);
            setLogs(responseJSON.data.logs.map(item => {
                item.status = BOOKING_STATUS[item.status]
                return item
            }))
            setKkImage({ name: 'kk.png', img: await fetchSecuredImg(responseJSON.data.kkFile, token) });
            setKtpImage({ name: 'ktp.png', img: await fetchSecuredImg(responseJSON.data.ktpFile, token) });
            if (responseJSON.data.kkPenjaminFile) {
                setKkPenjaminImage({
                    name: 'kk_penjamin.png',
                    img: await fetchSecuredImg(responseJSON.data.kkPenjaminFile, token)
                });
            }
            if (responseJSON.data.ktpPenjaminFile) {
                setKtpPenjaminImage({
                    name: 'ktp_penjamin.png',
                    img: await fetchSecuredImg(responseJSON.data.ktpPenjaminFile, token)
                });
            }
        });
    }
    const fetchSecuredImg = (path, token) => {
        try {
            return fetch(`${BASE_URL}/${path}`, {
                method: 'POST',
                headers: {
                    'Accept': 'image/png',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + token,
                }
            }).then(async response => {
                return await response.json();
            });
        } catch (e) {
            return undefined
        }
    }
    const handleDecide = async (status) => {
        Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah anda yakin ?',
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: 'Iya',
            denyButtonText: 'Tidak'
        }).then(async res => {
            if (res.isDenied) return;
            const token = (await Storage.get()).token;
            fetch(`${BASE_URL}/bookings/${id}/${status}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + token,
                },
            }).then(() => {
                window.open(`https://api.whatsapp.com/send?phone=${data.no_hp_1 ?? data.no_hp_2}&text=${waMessages[status]}`);
                fetchBooking();
            });
        });
    }

    const fetchCriteria = async () => {
        const token = (await Storage.get()).token;
        fetch(`${BASE_URL}/criteria`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setCriteria(responseJSON.data);
        });
    }

    const tabs = {
        log: 0,
        recommendation: 1,
    }
    const currentTab = () => {
        if (tabs[selectedTab] === tabs.log) {
            return <Table data={logs} columns={columns} height={0}/>
        }
        if (tabs[selectedTab] === tabs.recommendation) {
            return <Recommendation id={id} criteria={criteria}/>
        }
    }

    React.useEffect(async () => {
        await fetchBooking();
        await fetchCriteria();
    }, [])

    return (
        <View style={{ flex: 1 }}>
            <Text style={{ fontSize: 18, fontWeight: '500' }}>
                Detail Booking
            </Text>
            <View style={styles.rowContainer}>
                <Text style={{ fontSize: 14, marginRight: 10 }}>
                    Tanggal Daftar :
                </Text>
                <Text style={{ fontSize: 14, fontWeight: '500', marginTop: 5 }}>
                    {formatDisplayDateTime(data.created_at)}
                </Text>
            </View>
            <View style={{ justifyContent: 'center' }}>
                <View style={styles.infoContainer}>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>Status</Text>
                        <Text style={styles.infoText}>{data.status ?? '-'}</Text>
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={{ ...styles.infoRow, alignItems: 'flex-start' }}>
                        <Text style={{ ...styles.infoLabel, marginVertical: 10 }}>Nomor HP</Text>
                        <View style={{ alignSelf: 'baseline' }}>
                            {data.no_hp_1 ?
                                <View style={styles.phone}>
                                    <Text style={styles.infoText}>{data.no_hp_1 ?? '-'}</Text>
                                    <IconButton
                                        icon="whatsapp"
                                        iconType="font-awesome"
                                        iconColor={COLORS.GREEN}
                                        iconSize={28}
                                        onPress={() => openWA(data.no_hp_1)}
                                    />
                                </View> : <View/>
                            }
                            {data.no_hp_2 ?
                                <View style={styles.phone}>
                                    <Text style={styles.infoText}>{data.no_hp_2 ?? '-'}</Text>
                                    <IconButton
                                        icon="whatsapp"
                                        iconType="font-awesome"
                                        iconColor={COLORS.GREEN}
                                        iconSize={28}
                                        onPress={() => openWA(data.no_hp_2)}
                                    />
                                </View> : <View/>
                            }
                        </View>
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>Email</Text>
                        <Text style={styles.infoText}>{data.email ?? '-'}</Text>
                    </View>
                </View>
                <Text style={styles.infoTitle}>KTP Pemohon</Text>
                <View style={styles.infoContainer}>
                    {ktpImage.img ? <ImageComponent image={ktpImage.img} name={ktpImage.name}/> :
                        <Text>Image Not Found</Text>}
                </View>
                <Text style={styles.infoTitle}>KK Pemohon</Text>
                <View style={styles.infoContainer}>
                    {kkImage.img ? <ImageComponent image={kkImage.img} name={kkImage.name}/> :
                        <Text>Image Not Found</Text>}
                </View>

                {data.ktpPenjaminFile && data.kkPenjaminFile ? (
                    <View>
                        <Text style={styles.infoTitle}>KTP Penjamin</Text>
                        <View style={styles.infoContainer}>
                            {ktpPenjaminImage.img ?
                                <ImageComponent image={ktpPenjaminImage.img} name={ktpPenjaminImage.name}/> :
                                <Text>Image Not Found</Text>}
                        </View>
                        <Text style={styles.infoTitle}>KK Penjamin</Text>
                        <View style={styles.infoContainer}>
                            {kkPenjaminImage.img ?
                                <ImageComponent image={kkPenjaminImage.img} name={kkPenjaminImage.name}/> :
                                <Text>Image Not Found</Text>}
                        </View>
                    </View>
                ) : <></>}

                <Text style={styles.infoTitle}>Data Kredit</Text>
                <View style={styles.infoContainer}>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>Nama Motor</Text>
                        <Text style={styles.infoText}>{data.unit ?? '-'}</Text>
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>Kode Motor</Text>
                        <Text style={styles.infoText}>{data.kode ?? '-'}</Text>
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>Tipe Unit</Text>
                        <Text style={styles.infoText}>{data.type ?? '-'}</Text>
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>Warna Unit</Text>
                        <Text style={styles.infoText}>{data.color ?? '-'}</Text>
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>Harga OTR</Text>
                        <Text style={styles.infoText}>{formatMoney(data.otr)}</Text>
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>DP Gross</Text>
                        <Text style={styles.infoText}>{formatMoney(data.dp)}</Text>
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>Angsuran</Text>
                        <Text style={styles.infoText}>{formatMoney(data.angsuran)}</Text>
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>Tenor</Text>
                        <Text style={styles.infoText}>{`${data.top} x` ?? '-'}</Text>
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={styles.infoRow}>
                        <Text style={styles.infoLabel}>Nama STNK BPKB</Text>
                        <Text style={styles.infoText}>{data.nama_stnk_bpkb ?? '-'}</Text>
                    </View>
                </View>
                <View style={styles.buttonContainer}>
                    <PrimaryButton
                        title={'APPROVE'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={() => handleDecide(1)}
                        containerStyle={styles.actionButtonContainer}
                        buttonStyle={styles.actionButton}
                    />
                    <PrimaryButton
                        title={'DISAPPROVE'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={() => handleDecide(2)}
                        containerStyle={styles.actionButtonContainer}
                        buttonStyle={styles.actionButton}
                    />
                    <PrimaryButton
                        title={'CANCEL'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={() => handleDecide(3)}
                        containerStyle={styles.actionButtonContainer}
                        buttonStyle={styles.actionButton}
                    />
                    <PrimaryButton
                        title={'ADD DP'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={() => handleDecide(4)}
                        containerStyle={styles.actionButtonContainer}
                        buttonStyle={styles.actionButton}
                    />
                </View>
                {/*<Text style={styles.infoTitle}>Log Booking</Text>*/}
                <View style={styles.logContainer}>
                    <View style={{ ...styles.actionContainer, paddingBottom: 10 }}>
                        <Button
                            type='clear'
                            title='Log'
                            buttonStyle={styles.tabButton}
                            containerStyle={styles.tabButtonContainer}
                            titleStyle={styles.tabButtonText}
                            onPress={() => setSelectedTab('log')}
                        />
                        <Button
                            type='clear'
                            title='Rekomendasi'
                            buttonStyle={styles.tabButton}
                            containerStyle={styles.tabButtonContainer}
                            titleStyle={styles.tabButtonText}
                            onPress={() => setSelectedTab('recommendation')}
                        />
                    </View>
                    <Divider style={styles.divider}/>
                    <View style={{ marginTop: 10 }}>
                        {currentTab()}
                    </View>
                </View>
            </View>
        </View>
    )
}


const ImageComponent = props => {
    const { image, name } = props;
    const mediaQuery = setMediaQuery();
    const styles = InfoStyles(mediaQuery);

    var download = (function () {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data, fileName) {
            let blob = new Blob([data], { type: "image/png" })
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        };
    }());

    return (
        <View style={{ marginTop: 20 }}>
            <Image source={{ uri: image.img }} style={styles.image}/>
            <Divider style={styles.divider}/>
            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
                <TouchableOpacity onPress={() => {
                    fetch(image.img).then(async res => {
                        download(await res.blob(), name)
                    })
                }} style={styles.buttonSmall}>
                    <FontAwesome
                        name="download"
                        color={COLORS.RED}
                        size={24}
                    />
                    <Text style={{ fontSize: 10 }}>
                        Download
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}
