import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
    Dashboard,
    ListBookings,
    DetailBooking,
    DetailBookingLong,
    ListUsers,
    AddUser,
    DetailUser,
    ListDealers,
    AddDealer,
    DetailDealer,
    ListUnits,
    Login,
    Profile,
    AddDetailUnit,
    Pricelist
} from "../pages";
import Brochure from "../pages/units/Brochure";
import {
    RequireLogin,
    RequireAdmin,
    RequireDealer
} from "../route-guards/";
import Criteria from "../pages/criterias/Criteria";
import Storage from "../utilities/Storage";
import { ROLES } from "../utilities/Constants";

export default () => {
    const [isAdmin, setIsAdmin] = React.useState(false);
    React.useEffect(async () => {
        const { role } = await Storage.get();
        setIsAdmin((role >= ROLES.ADMIN[0] && role < ROLES.ADMIN[1]));
    });

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact element={<RequireLogin/>}>
                    <Route path='/' element={<Dashboard/>}>
                        {/*<Route exact element={<RequireDealer/>}>*/}
                        {/*    <Route path='/bookings' element={<ListBookings/>}/>*/}
                        {/*    <Route path="/bookings/:id/form" element={<DetailBookingLong/>}/>*/}
                        {/*    <Route path="/bookings/:id" element={<DetailBooking/>}/>*/}
                        {/*</Route>*/}
                        <Route exact element={<RequireAdmin/>}>
                            <Route path='/users' element={<ListUsers/>}/>
                            <Route path='/users/add' element={<AddUser/>}/>
                            <Route path='/users/:id' element={<DetailUser/>}/>
                            <Route path='/units' element={<ListUnits/>}/>
                            <Route path='/units/add' element={<AddDetailUnit/>}/>
                            <Route path='/units/:id' element={<AddDetailUnit/>}/>
                            <Route path='/units/pricelist' element={<Pricelist/>}/>
                            <Route path='/dealers' element={<ListDealers/>}/>
                            <Route path='/dealers/add' element={<AddDealer/>}/>
                            <Route path='/dealers/:id' element={<DetailDealer/>}/>
                            <Route path='/brochure' element={<Brochure/>}/>
                            {/*<Route path='/criteria' element={<Criteria/>}/>*/}
                        </Route>
                        <Route path="/profile" element={<Profile/>}/>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
