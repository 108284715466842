import { BASE_URL } from "../../utilities/Constants";
import { headerAuth, headers } from "../../utilities/Helpers";
import Swal from "sweetalert2";

export const validateInput = async (features, colors, specs) => {
    if (features.length <= 0) {
        await Swal.fire({
            title: 'Fitur unit masih kosong!',
            icon: 'error',
            confirmButtonText: 'Ok'
        });
        return;
    }
    if (colors.length <= 0) {
        await Swal.fire({
            title: 'Warna unit masih kosong!',
            icon: 'error',
            confirmButtonText: 'Ok'
        });
        return;
    }
    if (specs.length <= 0) {
        await Swal.fire({
            title: 'Spesifikasi unit masih kosong!',
            icon: 'error',
            confirmButtonText: 'Ok'
        });
        return;
    }
    return true;
}

export const createColors = async (id, data) => {
    const response = await fetch(`${BASE_URL}/motors/clear/color`, {
        method: 'POST',
        headers: await headers(),
        body: JSON.stringify({ motor_id: id })
    }).then(async response => await response.json());
    if (!response.success) return;
    for (const item of data) {
        const formData = new FormData();
        formData.append('file', item.file);
        formData.append('name', item.name);
        formData.append('kode', item.kode);
        formData.append('motor_id', id);
        const response = await fetch(`${BASE_URL}/motors/create/color`, {
            method: 'POST',
            headers: await headerAuth(),
            body: formData
        }).then(async response => await response.json());
        if (!response.success) {
            await Swal.fire({
                title: 'Gagal input warna motor!',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return false;
        }
    }
    return true;
};

export const createFeatures = async (id, data) => {
    const response = await fetch(`${BASE_URL}/motors/clear/features`, {
        method: 'POST',
        headers: await headers(),
        body: JSON.stringify({ motor_id: id })
    }).then(async response => await response.json());
    if (!response.success) return;
    for (const item of data) {
        const formData = new FormData();
        if (item.file) {
            formData.append('file', item.file);
        }
        formData.append('header', item.header);
        formData.append('detail', item.detail);
        formData.append('motor_id', id);
        const response = await fetch(`${BASE_URL}/motors/create/features`, {
            method: 'POST',
            headers: await headerAuth(),
            body: formData
        }).then(async response => await response.json());
        if (!response.success) {
            await Swal.fire({
                title: 'Gagal input fitur motor!',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return false;
        }
    }
    return true;
};

export const createSpecs = async (id, data) => {
    const response = await fetch(`${BASE_URL}/motors/create/specs`, {
        method: 'POST',
        headers: await headers(),
        body: JSON.stringify({
            motor_id: id,
            specs: data
        })
    }).then(async response => await response.json());
    if (!response.success) {
        await Swal.fire({
            title: 'Gagal input spesifikasi motor!',
            icon: 'error',
            confirmButtonText: 'Ok'
        });
        return false;
    }
    return true;
};