import React from "react";
import { View } from "react-native-web";
import { Button, Image, Input, Text } from "react-native-elements";
import { useNavigate } from "react-router-dom";
import { hub } from "../../images";
import { BASE_URL, setMediaQuery } from "../../utilities/Constants";
import Storage from "../../utilities/Storage";
import { IconButton } from "../../components/Button";
import COLORS from "../../utilities/Colors";

const Login = () => {
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const [email, setEmail] = React.useState({ value: '', error: '' });
    const [password, setPassword] = React.useState({ value: '', error: '' });
    const [hidePassword, setHidePassword] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const togglePassword = () => {
        setHidePassword(!hidePassword)
    }
    const handleLogin = async () => {
        setLoading(true);
        fetch(`${BASE_URL}/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email.value,
                password: password.value
            })
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                const { message } = responseJSON
                setEmail({
                    value: email.value,
                    error: message.email ?? ''
                });
                setPassword({
                    value: '',
                    error: message.password ?? ''
                });
                return;
            }
            await Storage.set(responseJSON.data);
            navigate('/');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <View style={styles.content}>
            <View style={styles.mainContainer}>
                <View style={styles.loginContainer}>
                    <View style={styles.titleContainer}>
                        <Text h3 style={styles.title}>
                            Portal AMBO
                        </Text>
                        <Text h4 style={styles.subtitle}>
                            Admin/Dealer
                        </Text>
                    </View>
                    <Text style={styles.brand}>
                        Astra Motor Bengkulu
                    </Text>
                    <Input
                        placeholder='Email Address'
                        inputStyle={styles.input}
                        containerStyle={styles.inputContainer}
                        errorStyle={styles.inputError}
                        errorMessage={email.error}
                        value={email.value}
                        onChangeText={value => setEmail({ value: value, error: '' })}
                    />
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                        <Input
                            placeholder='Password'
                            secureTextEntry={hidePassword}
                            inputStyle={styles.input}
                            containerStyle={styles.inputContainer}
                            errorStyle={styles.inputError}
                            errorMessage={password.error}
                            value={password.value}
                            onChangeText={value => setPassword({ value: value, error: '' })}
                        />
                        <IconButton
                            icon={hidePassword ? 'eye' : 'eye-slash'}
                            iconType='font-awesome-5'
                            iconColor={COLORS.BLACK}
                            onPress={togglePassword}
                        />
                    </View>
                    <Button
                        title="Login"
                        buttonStyle={styles.button}
                        containerStyle={styles.buttonContainer}
                        onPress={handleLogin}
                        loading={loading}
                    />
                </View>
                {mediaQuery.md ? <Image source={hub} resizeMode='contain' style={styles.image}/> : <></>}
            </View>
        </View>
    );
};

const setStyles = (mediaQuery) => ({
    content: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: COLORS.WHITE
    },
    mainContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: mediaQuery.mobile ? 'center' : 'space-around',
        marginHorizontal: mediaQuery.desktop ? 60 : 10
    },
    loginContainer: {
        width: mediaQuery.mobile ? '80%' : mediaQuery.desktop ? 480 : '100%'
    },
    input: {
        paddingHorizontal: 10,
        borderWidth: 0
    },
    inputError: {
        color: COLORS.RED,
        fontSize: 16
    },
    inputContainer: {
        flex: 1,
        padding: 0,
    },
    image: {
        width: mediaQuery.xl ? 640 : 400,
        height: 480
    },
    titleContainer: {
        cursor: 'default',
        flexDirection: 'row',
        alignItems: 'baseline',
        paddingHorizontal: 10
    },
    title: {
        color: COLORS.BLACK,
        fontWeight: 500,
        marginRight: 5,
    },
    subtitle: {
        color: COLORS.BLACK
    },
    brand: {
        color: COLORS.RED,
        fontSize: 20,
        fontWeight: 500,
        paddingBottom: 10,
        paddingHorizontal: 10,
        marginBottom: 20
    },
    button: {
        backgroundColor: COLORS.RED,
        borderRadius: 3,
    },
    buttonContainer: {
        flex: 1,
        marginHorizontal: 10,
        marginTop: 20
    }
});

export default Login;