import { Link, Outlet, useNavigate } from "react-router-dom";
import React from "react";
import COLORS from "../utilities/Colors";
import Storage from "../utilities/Storage";
import { ScrollView, View } from "react-native-web";
import { Button, Image, Text } from "react-native-elements";
import { ROLES, setMediaQuery } from "../utilities/Constants";
import { hub } from "../images";
import { Drawer } from "react-native-material-drawer/src";
import { IconButton } from "../components/Button";

const NavbarButton = props => {
    const mediaQuery = setMediaQuery()
    const styles = setStyles(mediaQuery);
    const BTN = () => (
        <Button
            title={props.title}
            iconPosition='top'
            icon={{
                name: props.icon,
                type: props.iconType,
                color: props.iconColor ?? COLORS.RED,
                size: props.iconSize ?? 20
            }}
            buttonStyle={styles.button}
            containerStyle={styles.buttonContainer}
            titleStyle={styles.buttonTitle}
            onPress={props.onPress}
        />
    );
    if (props.to) {
        return (
            <Link to={props.to ?? '/'} style={{ textDecorationLine: 'none' }}>
                <BTN/>
            </Link>
        )
    } else {
        return <BTN/>
    }
}

const LeftNavbar = (props) => {
    const { handleNavigation } = props;
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const [role, setRole] = React.useState(undefined);
    const handleLogout = async () => {
        await Storage.clear();
        navigate('login');
    };
    React.useEffect(async () => {
        setRole((await Storage.get()).role);
    });
    return (
        <View style={styles.leftNavbar}>
            <View style={{ flex: 1, alignItems: 'center' }}>
                <Image source={hub} resizeMode='contain' style={styles.brandImage}/>
                {/*{role >= ROLES.ALL || (role >= ROLES.DEALER[0] && role < ROLES.DEALER[1]) ? (*/}
                {/*    <NavbarButton*/}
                {/*        title='Booking'*/}
                {/*        icon='wpforms'*/}
                {/*        iconType='font-awesome'*/}
                {/*        to='/bookings'*/}
                {/*        // onPress={() => handleNavigation('booking')}*/}
                {/*    />*/}
                {/*) : <></>}*/}
                {role >= ROLES.ALL || (role >= ROLES.ADMIN[0] && role < ROLES.ADMIN[1]) ? (
                    <View>
                        <NavbarButton
                            title='Users'
                            icon='users'
                            iconType='font-awesome'
                            to='/users'
                            // onPress={() => handleNavigation('user')}
                        />
                        <NavbarButton
                            title='Dealer'
                            icon='warehouse'
                            iconType='font-awesome-5'
                            to='/dealers'
                            // onPress={() => handleNavigation('dealer')}
                        />
                        <NavbarButton
                            title='Unit'
                            icon='motorcycle'
                            iconType='font-awesome-5'
                            to='/units'
                            // onPress={() => handleNavigation('unit')}
                        />
                        <NavbarButton
                            title='Brochure'
                            icon='news'
                            iconType='entypo'
                            to='/brochure'
                            // onPress={() => handleNavigation('unit')}
                        />
                        {/*<NavbarButton*/}
                        {/*    title='Criteria'*/}
                        {/*    icon='clipboard-check'*/}
                        {/*    iconType='font-awesome-5'*/}
                        {/*    to='/criteria'*/}
                        {/*    // onPress={() => handleNavigation('criteria')}*/}
                        {/*/>*/}
                    </View>
                ) : <></>}
                <NavbarButton
                    title='Profile'
                    icon='user'
                    iconType='font-awesome-5'
                    to='/profile'
                    // onPress={() => handleNavigation('profile')}
                />
            </View>
            <View style={{ alignItems: 'center' }}>
                <NavbarButton
                    title='Logout'
                    icon='logout'
                    iconType='material-community'
                    onPress={() => handleLogout()}
                />
            </View>
        </View>
    )
}

const Title = () => {
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    return (
        <View style={{ marginHorizontal: 10 }}>
            <View style={styles.titleContainer}>
                <Text style={styles.title}>Portal AMBO</Text>
                <Text style={styles.subtitle}>Admin/Dealer</Text>
            </View>
            <Text style={styles.brand}>Astra Motor Bengkulu</Text>
        </View>
    )
}

const Dashboard = () => {
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);

    const [visible, setVisible] = React.useState(false);
    const hideMenu = () => setVisible(false);
    const showMenu = () => setVisible(true);

    const ROUTES = {
        booking: '/bookings',
        unit: '/units',
        dealer: '/dealers',
        user: '/users',
        profile: '/profile',
        criteria: '/criteria'
    };
    const handleNavigation = (route) => {
        const navigateTo = ROUTES[route];
        if (!navigateTo) return;
        if (!mediaQuery.desktop) hideMenu();
        navigate(navigateTo);
    };

    if (mediaQuery.desktop) {
        return (
            <View style={[styles.container, styles.body]}>
                <LeftNavbar handleNavigation={handleNavigation}/>
                <View style={{ flex: 1, paddingVertical: 10 }}>
                    <Title/>
                    <ScrollView contentContainerStyle={styles.content}>
                        <Outlet/>
                    </ScrollView>
                </View>
            </View>
        )
    }
    return (
        <View style={styles.container}>
            <Drawer
                open={visible}
                onClose={hideMenu}
                animationTime={250}
                width={64}
                drawerContent={<LeftNavbar handleNavigation={handleNavigation}/>}
            >
                <View style={styles.mobileBody}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                        <IconButton
                            icon='menu'
                            iconType='material-community'
                            iconColor={COLORS.RED}
                            onPress={showMenu}
                            containerStyle={{ marginRight: 5 }}
                        />
                        <Title/>
                    </View>
                    <ScrollView>
                        <Outlet/>
                    </ScrollView>
                </View>
            </Drawer>
        </View>
    );
}

const setStyles = mediaQuery => ({
    container: {
        width: "100%",
        height: "100vh",
    },
    body: {
        backgroundColor: COLORS.SMOKE,
        flexDirection: 'row',
        padding: 0
    },
    mobileBody: {
        backgroundColor: COLORS.SMOKE,
        flex: 1,
        padding: 10
    },
    leftNavbar: {
        backgroundColor: COLORS.WHITE,
        justifyContent: 'space-between',
        width: 64,
        height: '100%',
        paddingVertical: 10
    },
    mobileNavbar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    content: {
        flex: 1,
        paddingHorizontal: 15,
        paddingVertical: 10
    },
    brandImage: {
        width: 48,
        height: 48,
        marginBottom: 12
    },
    titleContainer: {
        cursor: 'default',
        flexDirection: 'row',
        alignItems: 'baseline'
    },
    title: {
        color: COLORS.BLACK,
        fontSize: 18,
        fontWeight: 500,
        marginRight: 5,
    },
    subtitle: {
        color: COLORS.BLACK,
        fontSize: 16,
        fontWeight: 400,
    },
    brand: {
        color: COLORS.RED,
        fontSize: 12,
        fontWeight: 500
    },
    button: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderWidth: 0
    },
    buttonContainer: {
        marginBottom: 16
    },
    buttonTitle: {
        color: COLORS.RED,
        fontSize: 12
    },
});

export default Dashboard;