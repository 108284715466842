import React from "react";
import { TextInput, View } from "react-native-web";
import { Icon, Text } from "react-native-elements";
import COLORS from "../utilities/Colors";
import { setMediaQuery } from "../utilities/Constants";
import { SecondaryButton } from "./Button";
import { Menu, MenuItem } from "react-native-material-menu";

export const InputDropdown = props => {
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const [isVisible, setIsVisible] = React.useState(false);
    const showDropdown = () => setIsVisible(true);
    const hideDropdown = () => setIsVisible(false);
    const { title, data, onSelect, value } = props;
    const handleSelect = (item) => {
        onSelect(item);
        hideDropdown()
    }
    return (
        <Menu
            visible={isVisible}
            onRequestClose={hideDropdown}
            style={{ width: '50%', maxWidth: 300 }}
            anchor={
                <SecondaryButton
                    title={value ?? title}
                    color={COLORS.GRAY}
                    icon="caretdown"
                    iconType="ant-design"
                    iconSize={10}
                    iconColor={COLORS.BLACK}
                    iconPosition="right"
                    fontSize={14}
                    titleStyle={styles.dropdownTitle}
                    buttonStyle={styles.dropdownButton}
                    onPress={showDropdown}
                />
            }
        >
            {data.map((item, i) => (
                <MenuItem key={i} onPress={() => handleSelect(item)}>
                    <Text>{item.label}</Text>
                </MenuItem>
            ))}
        </Menu>
    )
}
export const InputDate = (props) => {
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const { value, onChange } = props;
    return React.createElement('input', {
        type: 'datetime-local',
        value: value,
        onInput: (e) => {
            onChange(e.currentTarget.value)
        },
        style: { padding: 5, marginBottom: 1 }
    });
}

export const SearchBar = props => {
    const { searchQuery, onChangeText, placeholder } = props;
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    return (
        <View style={[styles.inputContainer, props.containerStyle]}>
            <TextInput
                placeholder={placeholder}
                style={styles.input}
                value={searchQuery}
                onChangeText={onChangeText}
            />
            <Icon
                name="search"
                type="font-awesome"
                size={18}
                color={COLORS.GRAY}
                containerStyle={{
                    paddingRight: 6,
                    paddingHorizontal: 6,
                    borderWidth: 0
                }}
            />
        </View>
    )
}

export const InputText = props => {
    const { value, onChangeText, placeholder, numeric, password } = props;
    const [error, setError] = React.useState(props.error);
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const onChangeNumeric = (value) => {
        setError('');
        if (isNaN(value)) {
            setError('Input harus berupa angka');
        }
        onChangeText(value);
    }
    React.useEffect(() => {
        setError(props.error)
    }, [props.error])
    return (
        <View style={props.containerStyle}>
            <Text style={{ fontSize: 12, fontWeight: '500' }}>{placeholder}</Text>
            <View style={{ ...styles.inputContainer, marginTop: 5 }}>
                <TextInput
                    secureTextEntry={password ?? false}
                    placeholder={placeholder}
                    style={styles.input}
                    value={value}
                    onChangeText={numeric ? onChangeNumeric : onChangeText}
                />
                {error ? <Text style={{ fontSize: 12, color: COLORS.RED }}>{error}</Text> : <></>}
            </View>
        </View>
    )
}

const setStyles = () => ({
    button: {
        backgroundColor: 'transparent',
        borderColor: COLORS.GRAY,
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: 16,
        paddingRight: 24
    },
    buttonTitle: {
        color: COLORS.BLACK,
        fontSize: 16,
        fontWeight: 500,
        marginLeft: 10
    },
    inputContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 2,
        borderColor: COLORS.GRAY,
        borderRadius: 3,
        paddingVertical: 6,
        paddingHorizontal: 5,
        backgroundColor: COLORS.WHITE
    },
    input: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        paddingHorizontal: 6,
        minWidth: 0,
        outlineStyle: 'none'
    },
    dropdownButton: {
        flex: 1,
        paddingVertical: 8,
        paddingLeft: 10,
        paddingRight: 3,
        borderRadius: 3,
        minWidth: 100,
        justifyContent: 'space-between',
        backgroundColor: COLORS.WHITE
    },
    dropdownTitle: {
        color: COLORS.BLACK,
        textAlign: 'left',
        marginRight: 6
    },
});