import React from "react";
import { View } from "react-native-web";
import { Link, useNavigate } from "react-router-dom";
import { Text } from "react-native-elements";
import { BASE_URL, setMediaQuery } from "../../utilities/Constants";
import Storage from "../../utilities/Storage";
import { InputDropdown, SearchBar } from "../../components/Input";
import Table from "../../components/Table";
import { PrimaryButton } from "../../components/Button";
import COLORS from "../../utilities/Colors";

export default () => {
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const columns = [
        { value: 0, label: 'Tipe', key: 'type', flex: 0.5 },
        { value: 1, label: 'Kode', key: 'kode', flex: 0.5 },
        { value: 2, label: 'Nama', key: 'name', flex: 2 },
        { value: 3, label: 'Harga', key: 'price', flex: 1, price: true },
        { value: 4, label: 'Status', key: 'status_label', flex: 0.5 },
    ];
    const filter = [
        { value: 0, label: 'Nama', key: 'name' },
        { value: 1, label: 'Kode', key: 'kode' },
    ]
    const statuses = [
        { value: -1, label: "Semua" },
        { value: 0, label: "Tidak Aktif" },
        { value: 1, label: "Aktif" }
    ]

    const [selectedFilter, setSelectedFilter] = React.useState(filter[0]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState({ value: -1, label: 'Semua' });
    const [selectedType, setSelectedType] = React.useState({ value: -1, label: 'Semua' });

    const [types, setTypes] = React.useState([]);
    const [units, setUnits] = React.useState([]);
    const [filteredUnits, setFilteredUnits] = React.useState([]);

    const fetchTypes = async (signal) => {
        const { token } = await Storage.get();
        fetch(`${BASE_URL}/motors/types/selection`, {
            signal: signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setTypes(Object.keys(responseJSON.data).map(key => {
                return { value: responseJSON.data[key].id, label: responseJSON.data[key].name };
            }));
        });
    };
    const fetchUnits = async (signal) => {
        const { token } = await Storage.get();
        fetch(`${BASE_URL}/motors`, {
            signal: signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setUnits(responseJSON.data);
        });
    };
    const filterUnits = () => setFilteredUnits(units.filter(unit => {
        let result = true;
        if (selectedStatus.value !== -1) {
            result = result && selectedStatus.value === unit.status;
        }
        if (selectedType.value !== -1) {
            result = result && selectedType.value === unit.type_id;
        }
        if (searchQuery !== '') {
            result = result && unit[selectedFilter.key].toLowerCase().includes(searchQuery.toLowerCase())
        }
        const status = statuses.find(status => status.value === unit.status);
        unit.status_label = status.label;
        return result;
    }));
    const onSelectRow = (unit) => {
        navigate(`/units/${unit.id}`);
    }

    React.useEffect(async () => {
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchTypes(signal);
        await fetchUnits(signal);
        return () => controller.abort();
    }, []);

    React.useEffect(() => {
        filterUnits();
    }, [selectedStatus, selectedType, searchQuery, units, selectedFilter]);

    return (
        <View style={{ flex: 1, marginTop: 10 }}>
            <View style={styles.flexContainer}>
                <View style={{ ...styles.rowContainer, marginRight: 5 }}>
                    <View style={{ flex: 1, marginRight: 5 }}>
                        <Text style={styles.filterTitle}>
                            Tipe Motor
                        </Text>
                        <InputDropdown
                            title={selectedType.label}
                            data={types}
                            onSelect={setSelectedType}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text style={styles.filterTitle}>
                            Status
                        </Text>
                        <InputDropdown
                            title={selectedStatus.label}
                            data={statuses}
                            onSelect={setSelectedStatus}
                        />
                    </View>
                </View>
                <View style={styles.rowContainer}>
                    <InputDropdown
                        title={selectedFilter.label}
                        data={filter}
                        onSelect={setSelectedFilter}
                    />
                    <SearchBar
                        searchQuery={searchQuery}
                        onChangeText={setSearchQuery}
                        placeholder={'Cari di Units'}
                        containerStyle={{ marginHorizontal: 5 }}
                    />
                </View>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 5 }}>
                <Link to="/units/add" style={{ flex: 1, textDecorationLine: 'none' }}>
                    <PrimaryButton
                        title={'TAMBAH UNIT'}
                        icon="plus"
                        iconType="ant-design"
                        iconSize={14}
                        iconPosition="left"
                        iconColor={COLORS.WHITE}
                        fontSize={12}
                        // onPress={() => navigate('/units/add')}
                        containerStyle={{ flex: 1, marginRight: 5 }}
                        color={COLORS.RED}
                    />
                </Link>
                <Link to="/units/pricelist" style={{ flex: 1, textDecorationLine: 'none' }}>
                    <PrimaryButton
                        title={'UPDATE PRICELIST'}
                        icon="plus"
                        iconType="ant-design"
                        iconSize={14}
                        iconPosition="left"
                        iconColor={COLORS.WHITE}
                        fontSize={12}
                        // onPress={() => navigate('/units/pricelist')}
                        containerStyle={{ flex: 1 }}
                        color={COLORS.RED}
                    />
                </Link>
            </View>
            <Table
                data={filteredUnits}
                columns={columns}
                // onSelect={onSelectRow}
                to="units"
            />
        </View>
    )
}


const setStyles = mediaQuery => ({
    filterTitle: { fontSize: 12, fontWeight: 'bold', marginBottom: 2, marginLeft: 2 },
    flexContainer: {
        flexDirection: mediaQuery.mobileL ? 'columns' : 'row',
        alignItems: mediaQuery.mobileL ? 'flex-start' : 'flex-end',
        width: '100%',
    },
    rowContainer: {
        flexDirection: 'row',
        minWidth: mediaQuery.mobileL ? '100%' : '50%',
        marginBottom: mediaQuery.mobileL ? 5 : 0
    }
});
