import React, { useEffect, useState } from 'react';
import { Text } from "react-native";
import { Image, View } from "react-native-web";
import Swal from "sweetalert2";
import { IconButton, PrimaryButton } from "../../components/Button";
import COLORS from "../../utilities/Colors";
import { BASE_URL } from "../../utilities/Constants";
import Storage from "../../utilities/Storage";

export default function Brochure() {
    const [images, setImages] = useState([]);

    const onChangeImage = (e, i) => {
        let items = [...images];
        let item = { ...items[i] };
        item.value = e.target.value;
        item.file = e.target.files[0];
        item.img = URL.createObjectURL(item.file)
        items[i] = item;
        setImages(items);
    }
    const addImage = () => {
        setImages(prevState => ([
            ...prevState, {
                value: '',
                file: undefined,
                img: '',
                error: ''
            }
        ]));
    }
    const deleteImage = (index) => {
        setImages(images.filter((_, i) => i !== index));
    }
    const submit = async () => {
        const { token } = await Storage.get();
        const formData = new FormData();
        for (const image of images) {
            formData.append('files[]', image.file);
        }
        const response = await fetch(`${BASE_URL}/brochure`, {
            method: 'POST',
            headers: { Authorization: "Bearer " + token },
            body: formData
        }).then(response => response.json());

        await Swal.fire({
            title: `${!response.success ? 'Gagal' : 'Berhasil'} update brosur`,
            icon: !response.success ? 'error' : 'success',
            confirmButtonText: 'Ok'
        });
    }

    useEffect(async () => {
        const { token } = await Storage.get();
        fetch(`${BASE_URL}/brochure`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
        }).then(async response => {
            const json = await response.json();
            if (!json.success || !json.data) return;
            const images = [];
            for (const path of json.data) {
                if (!path) continue;
                const file = await fetch(`${BASE_URL}/${path}`, {method: 'GET'})
                    .then(response => response.blob())
                    .then(blob => new File([blob], path));
                images.push({
                    value: '',
                    file: file,
                    img: `${BASE_URL}/${path}`,
                    error: ''
                });
            }
            setImages(images);
        })
    }, []);

    return (
        <React.Fragment>
            <View style={styles.row}>
                <View>
                    <Text style={{ fontSize: 18, fontWeight: '500' }}>
                        Brosur
                    </Text>
                    <Text style={{ fontSize: 12 }}>
                        * maksimum size file 2MB
                    </Text>
                </View>
                <PrimaryButton
                    icon="plus"
                    iconType="ant-design"
                    iconSize={14}
                    onPress={addImage}
                />
            </View>
            {images.map((img, i) => (
                <View key={i} style={{ ...styles.container, marginTop: 20 }}>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => onChangeImage(e, i)}
                    />
                    {!img.img ? <View/> :
                        <View style={{ flexDirection: 'row' }}>
                            <Image
                                source={{ uri: img.img }}
                                style={{
                                    resizeMode: 'contain',
                                    width: '100%',
                                    minHeight: img.img ? 200 : 0,
                                    marginVertical: 10
                                }}
                            />
                            <IconButton
                                icon="trash"
                                iconType="font-awesome"
                                iconColor={COLORS.RED}
                                iconSize={28}
                                onPress={() => deleteImage(i)}
                            />
                        </View>
                    }
                </View>
            ))}
            <View style={styles.container}>
                <PrimaryButton
                    title='Submit'
                    fontSize={12}
                    onPress={submit}
                />
            </View>
        </React.Fragment>
    )
};


const maxWidth = 640;
const styles = {
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: maxWidth,
        marginTop: 10
    },
    container: {
        maxWidth: maxWidth,
        marginTop: 10
    },
    divider: {
        borderColor: COLORS.GRAY,
        borderBottomWidth: 1,
        maxWidth: maxWidth,
        marginVertical: 10
    },
}