import AsyncStorage from "@react-native-async-storage/async-storage";

const KEYS = {
    ID: 'ID',
    TOKEN: 'TOKEN',
    ROLE: 'ROLE'
}

const Storage = {
    get: async () => {
        try {
            return {
                token: await AsyncStorage.getItem(KEYS.TOKEN),
                role: await AsyncStorage.getItem(KEYS.ROLE),
                id: await AsyncStorage.getItem(KEYS.ID)
            };
        } catch (e) {
            console.error(e);
        }
    },
    set: async (data) => {
        const { token, role, id } = data;
        try {
            if (token) await AsyncStorage.setItem(KEYS.TOKEN, token);
            if (role) await AsyncStorage.setItem(KEYS.ROLE, role);
            if (id) await AsyncStorage.setItem(KEYS.ID, id);
        } catch (e) {
            console.error(e);
        }
    },
    clear: async () => {
        try {
            await AsyncStorage.clear();
        } catch (e) {
            console.error(e);
        }
    }
}

export default Storage;