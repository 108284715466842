import React from "react";
import { View } from "react-native-web";
import { Text } from "react-native-elements";
import { InputDropdown, InputDate, SearchBar } from "../../components/Input";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { BASE_URL, setMediaQuery } from "../../utilities/Constants";
import { useNavigate } from "react-router-dom";
import { exportCSVFile } from "../../utilities/Helpers";
import Storage from "../../utilities/Storage";
import Table from "../../components/Table";
import COLORS from "../../utilities/Colors";
import moment from "moment";

const ListBooking = () => {
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const categories = [
        { value: 0, label: 'Email', key: 'email' },
        { value: 1, label: 'Unit', key: 'motor' },
        { value: 2, label: 'Dealer', key: 'dealer' },
    ];
    const statuses = [
        { value: -1, label: 'Semua' },
        { value: 0, label: 'Pending' },
        { value: 1, label: 'Approved' },
        { value: 2, label: 'Reject' },
        { value: 3, label: 'Cancel' },
        { value: 4, label: 'Add DP' },
    ];
    const columns = [
        { key: 'email', label: 'Email', flex: 2 },
        { key: 'motor', label: 'Unit', flex: 2 },
        { key: 'dealer', label: 'Dealer', flex: 2 },
        { key: 'status', label: 'Status', flex: 1 },
        { key: 'created_at', label: 'Tanggal Booking', flex: 2, date: true },
        { key: 'updated_at', label: 'Tanggal Update', flex: 2, date: true },
    ]
    const [selectedCategory, setSelectedCategory] = React.useState(categories[0]);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState({ value: -1, label: 'Semua' });
    const [filterStartDate, setFilterStartDate] = React.useState('');
    const [filterEndDate, setFilterEndDate] = React.useState('');
    const [bookings, setBookings] = React.useState([]);
    const [filteredBookings, setFilteredBookings] = React.useState([]);
    const [pendingCount, setPendingCount] = React.useState(0);
    const [bookingCount, setBookingCount] = React.useState(0);
    const fetchBookings = async (signal) => {
        const { token } = await Storage.get();
        fetch(`${BASE_URL}/bookings`, {
            signal: signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setBookings(responseJSON.data);
        });
    }
    const handleDownload = async () => {
        const { token } = await Storage.get();
        fetch(`${BASE_URL}/bookings/download`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            const arrHeaders = Object.keys(responseJSON.data[0]);
            const headers = arrHeaders.reduce((acc, curr) => (acc[curr] = curr, acc), {});
            exportCSVFile(headers, responseJSON.data, "bookings");
        });
    }
    const onPressBooking = (booking) => {
        if (booking.has_form) navigate(`/bookings/${booking.id}/form`);
        else navigate(`/bookings/${booking.id}`);
    }
    const filterBookings = () => {
        const rangeDate = {
            start: filterStartDate ? moment(filterStartDate) : undefined,
            end: filterEndDate ? moment(filterEndDate) : undefined
        }
        const list = bookings.filter(booking => {
            let status = true;
            if (selectedStatus.value !== -1) {
                status = selectedStatus.value === booking.status_id;
            }
            if (rangeDate.start) {
                status = moment(booking.created_at).isAfter(rangeDate.start);
            }
            if (rangeDate.end) {
                status = moment(booking.created_at).isBefore(rangeDate.end);
            }
            if (searchQuery !== '') {
                status = booking[selectedCategory.key].toLowerCase().includes(searchQuery.toLowerCase())
            }
            return status
        });
        setBookingCount(list.length);
        setPendingCount(list.filter(booking => booking.status_id === 0).length);
        setFilteredBookings(list);
    }

    React.useEffect(async () => {
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchBookings(signal);
        return () => controller.abort();
    }, []);
    React.useEffect(() => {
        const date = {
            start: moment().subtract(7, 'days').format('YYYY-MM-DDThh:mm'),
            end: moment().add(1, 'days').format('YYYY-MM-DDThh:mm')
        }
        setFilterStartDate(date.start);
        setFilterEndDate(date.end);
    }, []);
    React.useEffect(() => {
        filterBookings();
    }, [bookings, selectedStatus, filterStartDate, filterEndDate, searchQuery]);

    return (
        <View style={{ flex: 1 }}>
            <View style={{ ...styles.flexContainerL, marginBottom: 10 }}>
                <View style={{ ...styles.flexContainer, marginBottom: mediaQuery.tablet ? 5 : 0 }}>
                    <View style={{ marginBottom: mediaQuery.mobileM ? 5 : 0, marginRight: 10 }}>
                        <Text style={styles.filterTitle}>
                            Tanggal Booking
                        </Text>
                        <View style={styles.flexContainer}>
                            <InputDate
                                value={filterStartDate}
                                onChange={date => setFilterStartDate(date)}
                            />
                            <Text style={{ fontSize: 12, marginHorizontal: 10, marginVertical: 2 }}>
                                s/d
                            </Text>
                            <InputDate
                                value={filterEndDate}
                                onChange={date => setFilterEndDate(date)}
                            />
                        </View>
                    </View>
                    <View style={{ marginBottom: mediaQuery.mobileL ? 5 : 0 }}>
                        <Text style={styles.filterTitle}>
                            Status
                        </Text>
                        <InputDropdown
                            title={selectedStatus.label}
                            data={statuses}
                            onSelect={setSelectedStatus}
                        />
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', marginLeft: mediaQuery.tablet ? 0 : 5 }}>
                    <InputDropdown
                        title={selectedCategory.label}
                        data={categories}
                        onSelect={setSelectedCategory}
                    />
                    <SearchBar
                        searchQuery={searchQuery}
                        onChangeText={setSearchQuery}
                        placeholder={'Cari di Booking'}
                        containerStyle={{ marginLeft: 5 }}
                    />
                </View>
            </View>
            <View style={styles.rowContainer}>
                <View style={{ flexDirection: 'row', marginRight: 20 }}>
                    <Text style={{ marginRight: 10 }}>
                        Pending: {pendingCount}
                    </Text>
                    <Text>
                        Total: {bookingCount}
                    </Text>
                </View>
                <PrimaryButton
                    title={'Download'}
                    icon="download"
                    iconType="ant-design"
                    iconSize={12}
                    iconPosition="right"
                    iconColor={COLORS.BLUE}
                    fontSize={12}
                    onPress={handleDownload}
                />
            </View>
            <Table
                data={filteredBookings}
                columns={columns}
                to="bookings"
                extend={"form"}
                // onSelect={(value) => onPressBooking(value)}
            />
        </View>
    )
}

const setStyles = mediaQuery => ({
    filterTitle: { fontSize: 12, fontWeight: 'bold', marginBottom: 2, marginLeft: 2 },
    flexContainer: {
        flexDirection: mediaQuery.mobileL ? 'col' : 'row',
        alignItems: mediaQuery.mobileL ? 'flex-start' : 'flex-end',
    },
    flexContainerL: {
        flexDirection: mediaQuery.tablet ? 'col' : 'row',
        alignItems: mediaQuery.tablet ? 'flex-start' : 'flex-end',
    },
    rowContainer: { flexDirection: 'row', alignItems: 'center', marginBottom: 10 }
});

export default ListBooking;
