import React from "react";
import { ScrollView, View } from "react-native-web";
import { BASE_URL } from "../../utilities/Constants";
import { Text } from "react-native-elements";
import Storage from "../../utilities/Storage";
import Table from "../../components/Table";

export default (props) => {
    const { unitID } = props;
    const [labels, setLabels] = React.useState([]);
    const [data, setData] = React.useState([]);

    const fetchCredit = async (signal) => {
        const { token } = await Storage.get();
        fetch(`${BASE_URL}/motors/${unitID}/credit`, {
            signal: signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success || !responseJSON.data){return;}
            const dp = responseJSON.data.map(item => item.down_payment);
            const headers = responseJSON.label;
            headers.unshift('Uang Muka');
            setData(responseJSON.detail.map((item, i) => {
                item.unshift(dp[i]);
                return item;
            }));
            setLabels(headers.map((item, i) => ({
                key: i,
                label: item,
                flex: 0.5
            })));
        })
    }

    React.useEffect(async () => {
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchCredit(signal);
        return () => controller.abort();
    }, []);

    return (
        <View style={{ paddingVertical: 10, maxHeight: 400 }}>
            <Text style={{ fontSize: 12, paddingHorizontal: 10, alignSelf: 'end' }}>
                * Satuan dalam ribuan
            </Text>
            {
                data.length === 0 ? <Text style={{ fontSize: 18, paddingHorizontal: 10, alignSelf: 'justify' }}>
                    Data Credit tidak ditemukan
                </Text> : <ScrollView horizontal style={{ flex: 1, marginTop: 5 }}>
                    <Table data={data} columns={labels}/>
                </ScrollView>
            }

        </View>
    )
}
