import React from "react";
import { View } from "react-native-web";
import { BASE_URL } from "../../utilities/Constants";
import { Divider, Text } from "react-native-elements";
import Swal from "sweetalert2";
import { IconButton, PrimaryButton, SecondaryButton } from "../../components/Button";
import COLORS from "../../utilities/Colors";
import { InputDropdown, InputText } from "../../components/Input";
import Storage from "../../utilities/Storage";
import { createSpecs } from "./Functions";

export default (props) => {
    const { unitID, styles, update, data, onFinish } = props;
    const [specs, setSpecs] = React.useState(data ?? []);
    const [headers, setHeaders] = React.useState(data ?? []);
    const [saved, setSaved] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const fetchSpecs = async (signal) => {
        const { token } = await Storage.get();
        fetch(`${BASE_URL}/motors/${unitID}/specifications`, {
            signal: signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setHeaders(responseJSON.headers.map(
                key => ({ value: key, label: key })
            ));
            setSpecs(responseJSON.specs);
        });
    }

    const finish = async () => {
        for (const spec of specs) {
            if (!spec.detail || !spec.header || !spec.detail) {
                await Swal.fire({
                    title: 'Gagal input specs motor, ada elemen yang kosong',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            }
        }
        if (update) {
            setLoading(true);
            await createSpecs(unitID, specs)
                .then(() => setLoading(false));
        } else {
            setSaved(true);
        }
        onFinish(specs);
    }

    const addSpecification = () => {
        setSpecs(prevState => ([...prevState, { name: '', detail: '', header: '' }]));
    }
    const deleteSpecification = (index) => {
        const newSpecs = specs.filter((_, i) => i !== index);
        setSpecs(newSpecs);
    }
    const setSpecificationsItem = (index, value, attr) => {
        let items = [...specs];
        let item = { ...items[index] };
        item[attr] = value;
        items[index] = item;
        setSpecs(items);
    }
    React.useEffect(async () => {
        // if (!update) return;
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchSpecs(signal);
        return () => controller.abort();
    }, []);

    return (
        <View styles={{ flex: 1 }}>
            <View style={{ ...styles.infoRow, justifyContent: 'space-between' }}>
                <Text style={styles.infoTitle}>
                    Spesifikasi
                </Text>
                <Text style={styles.smallInfo}>
                    * save dahulu sebelum berpindah tab
                </Text>
            </View>
            {specs.map((spec, i) => (
                <View key={i}>
                    <Divider style={styles.divider}/>
                    <View style={{ ...styles.infoRow, justifyContent: 'space-between' }}>
                        <View style={{ flex: 1, marginRight: 10, paddingHorizontal: 10 }}>
                            <View style={{ marginBottom: 5 }}>
                                <Text style={styles.filterTitle}>
                                    Header
                                </Text>
                                <InputDropdown
                                    title="Header"
                                    value={spec.header}
                                    data={headers}
                                    onSelect={value => {
                                        setSpecificationsItem(i, value.value, 'header')
                                    }}
                                />
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <InputText
                                    placeholder="Nama"
                                    value={spec.name}
                                    onChangeText={text => setSpecificationsItem(i, text, 'name')}
                                    containerStyle={{ flex: 0.5, marginRight: 5 }}
                                />
                                <InputText
                                    placeholder="Detail"
                                    value={spec.detail}
                                    onChangeText={text => setSpecificationsItem(i, text, 'detail')}
                                    containerStyle={{ flex: 1 }}
                                />
                            </View>
                        </View>
                        <IconButton
                            icon="trash"
                            iconType="font-awesome"
                            iconColor={COLORS.RED}
                            iconSize={28}
                            onPress={() => deleteSpecification(i)}/>
                    </View>
                </View>
            ))}
            {saved ?
                <Text style={{ fontSize: 12, color: COLORS.GREEN, paddingHorizontal: 10 }}>
                    Data tersimpan
                </Text> : <></>
            }
            <View style={styles.actionContainer}>
                <PrimaryButton
                    title={'SAVE'}
                    fontSize={12}
                    onPress={finish}
                    loading={loading}
                    containerStyle={{ flex: 1, marginRight: 5 }}
                    buttonStyle={styles.actionButton}
                />
                <SecondaryButton
                    icon="plus"
                    iconType="ant-design"
                    iconSize={14}
                    iconColor={COLORS.BLUE}
                    onPress={addSpecification}
                    buttonStyle={styles.secondaryActionButton}
                />
            </View>
        </View>
    )
}
