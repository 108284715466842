import React from "react";
import { Text, View } from "react-native-web";
import Select2 from "react-native-select-two";
import { BASE_URL, setMediaQuery } from "../../utilities/Constants";
import { InputDropdown, InputText } from "../../components/Input";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import COLORS from "../../utilities/Colors";
import Storage from "../../utilities/Storage";
import Swal from "sweetalert2";
import { Divider } from "react-native-elements";
import { formatMoney } from "../../utilities/Helpers";
import InfoStyles from "../../utilities/InfoStyles";

export default () => {
    const mediaQuery = setMediaQuery();
    const styles = InfoStyles(mediaQuery);
    const [name, setName] = React.useState({ value: '', error: '' });
    const [email, setEmail] = React.useState({ value: '', error: '' });
    const [dealer, setDealer] = React.useState({ value: '', error: '' });
    const [phone, setPhone] = React.useState({ value: '', error: '' });
    const [oldPassword, setOldPassword] = React.useState({ value: '', error: '' });
    const [newPassword, setNewPassword] = React.useState({ value: '', error: '' });
    const [confirmPassword, setConfirmPassword] = React.useState({ value: '', error: '' });

    const fetchUser = async (signal) => {
        const { id, token } = await Storage.get();
        await fetch(`${BASE_URL}/users/${id}`, {
            signal: signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setEmail({ value: responseJSON.user.email, error: '' });
            setName({ value: responseJSON.user.name, error: '' });
            setPhone({ value: responseJSON.user.phone_number, error: '' });
            setDealer({ value: responseJSON.user.dealer_id, error: '' });
        });
    }
    React.useEffect(async () => {
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchUser(signal);
        return () => controller.abort();
    }, [])

    const updateUser = async () => {
        const { id, token } = await Storage.get();
        await fetch(`${BASE_URL}/users/update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                name: name.value,
                email: email.value,
                phone_number: phone.value,
                dealer_id: dealer.value,
                id: id
            })
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                const message = responseJSON.message;
                setEmail({ value: email.value, error: message.email ? message.email[0] : '' });
                setName({ value: name.value, error: message.name ? message.name[0] : '' });
                setPhone({ value: phone.value, error: message.phone_number ? message.phone_number[0] : '' });
                return;
            }
            await Swal.fire({
                title: 'Berhasil update user',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        });
    }
    const changePassword = async () => {
        const { id, token } = await Storage.get();
        await fetch(`${BASE_URL}/users/password/change`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                old_password: oldPassword.value,
                password: newPassword.value,
                password_confirmation: confirmPassword.value,
                id: id
            })
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                const message = responseJSON.message;
                setOldPassword({ value: '', error: message.old_password ? message.old_password[0] : '' });
                setNewPassword({ value: '', error: message.password ? message.password [0] : '' });
                setConfirmPassword({
                    value: '',
                    error: message.password_confirmation ? message.password_confirmation[0] : ''
                });
                return;
            }
            await Swal.fire({
                title: 'Berhasil ganti password',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        });
    }

    return (
        <View style={{ flex: 1 }}>
            <View style={styles.infoContainer}>
                <View style={styles.infoRow}>
                    <InputText
                        placeholder='Nama'
                        error={name.error}
                        value={name.value}
                        onChangeText={value => setName({ value: value, error: '' })}
                        containerStyle={styles.inputContainer}
                    />
                </View>
                <Divider style={styles.divider}/>
                <View style={styles.infoRow}>
                    <InputText
                        placeholder='Email'
                        error={email.error}
                        value={email.value}
                        onChangeText={value => setEmail({ value: value, error: '' })}
                        containerStyle={styles.inputContainer}
                    />
                </View>
                <Divider style={styles.divider}/>
                <View style={styles.infoRow}>
                    <InputText
                        numeric
                        placeholder='Nomor Telepon'
                        error={phone.error}
                        value={phone.value}
                        onChangeText={value => setPhone({ value: value, error: '' })}
                        containerStyle={styles.inputContainer}
                    />
                </View>
                <Divider style={styles.divider}/>
                <View style={styles.buttonContainer}>
                    <PrimaryButton
                        title={'UPDATE PROFILE'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={() => updateUser()}
                        containerStyle={styles.actionButtonContainer}
                        buttonStyle={styles.actionButton}
                    />
                </View>
            </View>

            <View style={styles.infoContainer}>
                <View style={styles.infoRow}>
                    <InputText
                        password
                        placeholder='Password Lama'
                        error={oldPassword.error}
                        value={oldPassword.value}
                        onChangeText={value => setOldPassword({ value: value, error: '' })}
                        containerStyle={styles.inputContainer}
                    />
                </View>
                <Divider style={styles.divider}/>
                <View style={styles.infoRow}>
                    <InputText
                        password
                        placeholder='Password Baru'
                        error={newPassword.error}
                        value={newPassword.value}
                        onChangeText={value => setNewPassword({ value: value, error: '' })}
                        containerStyle={styles.inputContainer}
                    />
                </View>
                <Divider style={styles.divider}/>
                <View style={styles.infoRow}>
                    <InputText
                        password
                        placeholder='Konfirmasi Password'
                        error={confirmPassword.error}
                        value={confirmPassword.value}
                        onChangeText={value => setConfirmPassword({ value: value, error: '' })}
                        containerStyle={styles.inputContainer}
                    />
                </View>
                <Divider style={styles.divider}/>
                <View style={styles.buttonContainer}>
                    <PrimaryButton
                        title={'GANTI PASSWORD'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={() => changePassword()}
                        containerStyle={styles.actionButtonContainer}
                        buttonStyle={styles.actionButton}
                    />
                </View>
            </View>
        </View>
    );
};
