import React from "react";
import { Text, View } from "react-native-web";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, setMediaQuery } from "../../utilities/Constants";
import Swal from "sweetalert2";
import COLORS from "../../utilities/Colors";
import { headers } from "../../utilities/Helpers";
import { InputDropdown, InputText } from "../../components/Input";
import { Button, Divider, Switch } from "react-native-elements";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import UnitColors from "./UnitColors";
import UnitSpecs from "./UnitSpecs";
import UnitFeatures from "./UnitFeatures";
import UnitCredit from "./UnitCredit";
import InfoStyles from "../../utilities/InfoStyles";
import { createColors, createSpecs, createFeatures } from "./Functions";
export default () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const styles = InfoStyles(mediaQuery);
    const TAB = { colors: 0, specs: 1, features: 2, credit: 3 }
    const RANGES = ["Low", "Mid", 'High'];

    // region STATES
    const [data, setData] = React.useState({
        type_id: -1, type_label: '', name: '', kode: '', price: 0, range: 0, range_label: 'Low', family: '', status: 1
    });
    const setDataValue = (value, key) => {
        setData(prevState => ({ ...prevState, [key]: value }));
    };
    const [error, setError] = React.useState({
        type: '', kode: '', name: '', price: '', range: '', family: '', status: ''
    });
    const setErrorValue = (value, key) => {
        setError(prevState => ({ ...prevState, [key]: value }));
    };

    const [unitID, setUnitID] = React.useState(id ?? 0);
    const [loading, setLoading] = React.useState(false);
    const [types, setTypes] = React.useState([]);
    const [colors, setColors] = React.useState([]);
    const [specs, setSpecs] = React.useState([]);
    const [features, setFeatures] = React.useState([]);
    const [tabIndex, setTabIndex] = React.useState(0);
    // endregion

    const SelectedTab = () => {
        if (tabIndex === TAB.colors) {
            return <UnitColors unitID={unitID} styles={styles} onFinish={data => setColors(data)} update={!!id}
                               data={colors}/>
        }
        if (tabIndex === TAB.features) {
            return <UnitFeatures unitID={unitID} styles={styles} onFinish={data => setFeatures(data)} update={!!id}
                                 data={features}/>
        }
        if (tabIndex === TAB.specs) {
            return <UnitSpecs unitID={unitID} styles={styles} onFinish={data => setSpecs(data)} update={!!id}
                              data={specs}/>
        }
        if (tabIndex === TAB.credit) {
            return <UnitCredit unitID={unitID} styles={styles}/>
        }
    }

    // REGION FETCH
    const fetchTypes = async (signal) => {
        const response = await fetch(`${BASE_URL}/motors/types`, {
            signal: signal,
            method: 'POST',
            headers: await headers()
        }).then(async res => await res.json());
        setTypes(response.data.map(item => ({
            value: item.id,
            label: item.name
        })));
        setDataValue(response.data[0].id, 'type_id');
        setDataValue(response.data[0].name, 'type_label');
    };

    const fetchUnit = async () => {
        const response = await fetch(`${BASE_URL}/motors/${id}`, {
            method: 'POST',
            headers: await headers()
        }).then(async res => await res.json());
        const type = types.find(type => type.value === response.data.type_id);
        setData({ ...response.data, type_label: type.label, range_label: RANGES[response.data.range]});
        setUnitID(response.data.id);
    };
    // ENDREGION

    // REGION EVENT HANDLER
    const handleCreate = async () => {
        if (loading) return;
        // if (!await validateInput(features, colors, specs)) return;
        setLoading(true);
        const response = await fetch(`${BASE_URL}/motors/create`, {
            method: 'POST',
            headers: await headers(),
            body: JSON.stringify({ id: unitID, ...data })
        }).then(async res => await res.json());
        let success = await createColors(response.id, colors);
        // success = success && await createSpecs(response.id, specs);
        // success = success && await createFeatures(response.id, features);
        if (!success) {
            setLoading(false);
            return;
        }
        if (!response.success) {
            await Swal.fire({
                title: 'Gagal Tambah Unit',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        } else {
            await Swal.fire({
                title: 'Berhasil Tambah Unit',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }
        setLoading(false);
    };
    const handleUpdate = async () => {
        if (loading) return;
        setLoading(true);
        const response = await fetch(`${BASE_URL}/motors/update/${unitID}`, {
            method: 'POST',
            headers: await headers(),
            body: JSON.stringify({ id: unitID, ...data })
        }).then(async res => await res.json());
        if (!response.success) {
            await Swal.fire({
                title: 'Gagal Update Unit',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        } else {
            await Swal.fire({
                title: 'Berhasil Update Unit',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        }
        setLoading(false);
    };
    const handleDelete = async () => {
        if (loading) return;
        setLoading(true);
        const response = await fetch(`${BASE_URL}/motors/delete/${unitID}`, {
            method: 'POST',
            headers: await headers()
        }).then(async res => await res.json());
        if (!response.success) {
            await Swal.fire({
                title: 'Gagal Delete Unit',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        } else {
            await Swal.fire({
                title: 'Berhasil Delete Unit',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            navigate('/units')
        }
        setLoading(false);
    };

    // ENDREGION

    React.useEffect(async () => {
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchTypes(signal);
        return () => controller.abort();
    }, []);

    React.useEffect(async () => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (types[0] && id) await fetchUnit(signal);
        return () => controller.abort();
    }, [types]);

    return (
        <View style={{ flex: 1 }}>
            <View style={styles.infoContainer}>
                <View style={styles.infoBlock}>
                    <Text style={{ fontSize: 12, fontWeight: '500', marginBottom: 5 }}>
                        Tipe Unit
                    </Text>
                    <InputDropdown
                        title={data.type_label}
                        value={data.type_label}
                        data={types}
                        onSelect={type => {
                            setDataValue(type.label, 'type_label');
                            setDataValue(type.value, 'type_id');
                        }}
                    />
                </View>
                <Divider style={styles.divider}/>
                <View style={styles.infoRow}>
                    <InputText
                        placeholder='Kode Unit'
                        value={data.kode}
                        error={error.kode}
                        onChangeText={value => setDataValue(value, 'kode')}
                        containerStyle={styles.inputContainer}
                    />
                </View>
                <Divider style={styles.divider}/>
                <View style={styles.infoRow}>
                    <InputText
                        placeholder='Nama Unit'
                        value={data.name}
                        error={error.name}
                        onChangeText={value => setDataValue(value, 'name')}
                        containerStyle={styles.inputContainer}
                    />
                </View>
                <Divider style={styles.divider}/>
                <View style={styles.infoRow}>
                    <InputText
                        placeholder='Jenis Unit'
                        value={data.family}
                        error={error.family}
                        onChangeText={value => setDataValue(value, 'family')}
                        containerStyle={styles.inputContainer}
                    />
                </View>
                <Divider style={styles.divider}/>
                {
                    data.type_id !== 4 && (
                        <View style={styles.infoBlock}>
                            <Text style={{ fontSize: 12, fontWeight: '500', marginBottom: 5 }}>
                                Range Harga
                            </Text>
                            <InputDropdown
                                title={data.range_label}
                                value={data.range_label}
                                data={[
                                    { value: 0, label: 'Low' },
                                    { value: 1, label: 'Mid' },
                                    { value: 2, label: 'High' }
                                ]}
                                onSelect={item => {
                                    setDataValue(item.label, 'range_label');
                                    setDataValue(item.value, 'range');
                                }}
                            />
                        </View>
                    )
                }
                <Divider style={styles.divider}/>
                <View style={styles.infoRow}>
                    <InputText
                        numeric
                        placeholder='Harga Unit'
                        value={data.price}
                        error={error.price}
                        onChangeText={value => setDataValue(value, 'price')}
                        containerStyle={styles.inputContainer}
                    />
                </View>
                <Divider style={styles.divider}/>
                <View style={{ ...styles.infoRow, justifyContent: 'space-between' }}>
                    <Switch
                        value={data.status}
                        onValueChange={value => setDataValue(value, 'status')}
                    />
                    <Text style={{ color: data.status ? COLORS.GREEN : COLORS.RED, fontWeight: '500' }}>
                        {data.status ? "Aktif" : "Tidak Aktif"}
                    </Text>
                </View>
                <Divider style={styles.divider}/>
                <View style={styles.actionContainer}>
                    <PrimaryButton
                        title={(id ? 'UPDATE' : 'TAMBAH') + ' MOTOR'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={id ? handleUpdate : handleCreate}
                        containerStyle={styles.statusButtonContainer}
                        buttonStyle={styles.statusButton}
                        loading={loading}
                    />
                    {id ? (
                        <SecondaryButton
                            title={'DELETE MOTOR'}
                            fontSize={mediaQuery.mobileM ? 10 : 12}
                            onPress={handleDelete}
                            color={COLORS.RED}
                            containerStyle={{ ...styles.statusButtonContainer, marginLeft: 5 }}
                            buttonStyle={styles.statusButton}
                            loading={loading}
                        />
                    ) : <></>}
                </View>
            </View>
            <View style={{ ...styles.infoContainer, marginBottom: 40 }}>
                <View style={{ ...styles.actionContainer, paddingVertical: 5 }}>
                    <Button
                        type='clear'
                        title='Warna'
                        buttonStyle={styles.tabButton}
                        containerStyle={styles.tabButtonContainer}
                        titleStyle={styles.tabButtonText}
                        onPress={() => setTabIndex(TAB.colors)}
                    />
                    <Button
                        type='clear'
                        title='Specs'
                        buttonStyle={styles.tabButton}
                        containerStyle={styles.tabButtonContainer}
                        titleStyle={styles.tabButtonText}
                        onPress={() => setTabIndex(TAB.specs)}
                    />
                    <Button
                        type='clear'
                        title='Fitur'
                        buttonStyle={styles.tabButton}
                        containerStyle={styles.tabButtonContainer}
                        titleStyle={styles.tabButtonText}
                        onPress={() => setTabIndex(TAB.features)}
                    />
                    {id ? (
                        <Button
                            type='clear'
                            title='Credit'
                            buttonStyle={styles.tabButton}
                            containerStyle={styles.tabButtonContainer}
                            titleStyle={styles.tabButtonText}
                            onPress={() => setTabIndex(TAB.credit)}
                        />
                    ) : <View/>}
                </View>
                <Divider style={styles.divider}/>
                {SelectedTab()}
            </View>
        </View>
    )
}
