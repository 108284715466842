import React from "react";
import { Text, View } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { BASE_URL, setMediaQuery } from "../../utilities/Constants";
import Storage from "../../utilities/Storage";
import COLORS from "../../utilities/Colors";
import { InputText } from "../../components/Input";
import Table from "../../components/Table";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { CheckBox, Switch } from "react-native-elements";
import Swal from "sweetalert2";

export default () => {
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const columns = [
        { key: 'judul', label: 'Judul', flex: 1 },
        { key: 'minimum', label: 'Min', flex: 1 },
        { key: 'maksimum', label: 'Maks', flex: 1 },
        { key: 'skala', label: 'Skala', flex: 1 },
        { key: 'persen', label: 'Persen', flex: 1 },
        { key: 'state', label: 'Status', flex: 1 }
    ]
    const references = [
        { key: 'nama_stnk_bpkb', label: 'Nama STNK dan BPKB' },
        { key: 'otr', label: 'OTR' },
        { key: 'dp', label: 'Uang Muka' },
        { key: 'angsuran', label: 'Angsuran' },
        { key: 'top', label: 'Tenor' },
    ]
    const [criteria, setCriteria] = React.useState([]);
    const [judul, setJudul] = React.useState({ value: '', error: '' });
    const [minimum, setMinimum] = React.useState({ value: 0, error: '' });
    const [maksimum, setMaksimum] = React.useState({ value: 0, error: '' });
    const [isFixed, setIsFixed] = React.useState({ value: true, error: '' });
    const [status, setStatus] = React.useState({ value: true, error: '' });
    const [skala, setSkala] = React.useState({ value: 0, error: '' });
    const [column, setColumn] = React.useState({ value: '', error: '' });
    const [id, setId] = React.useState({ value: '', error: '' });
    const [isUpdating, setIsUpdating] = React.useState(false);

    const fetchCriteria = async (signal) => {
        const token = (await Storage.get()).token;
        fetch(`${BASE_URL}/criteria`, {
            method: 'GET',
            signal: signal,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            for (const item of responseJSON.data) {
                item.persen = item.is_fixed ? 'Yes' : 'No';
                item.state = item.status ? 'Active' : 'Disable';
            }
            setCriteria(responseJSON.data);
        });
    };

    React.useEffect(async () => {
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchCriteria(signal);
        return () => controller.abort();
    }, []);

    const setError = (message) => {
        setJudul({ value: judul.value, error: message.judul ? message.judul[0] : '' });
        setMinimum({ value: minimum.value, error: message.minimum ? message.minimum[0] : '' });
        setMaksimum({ value: maksimum.value, error: message.maksimum ? message.maksimum[0] : '' });
        setSkala({ value: skala.value, error: message.skala ? message.skala[0] : '' });
    }

    const onPressTable = async (criteria) => {
        console.log(criteria)
        setIsUpdating(true);
        setJudul({ value: criteria.judul, error: '' });
        setMinimum({ value: criteria.minimum, error: '' });
        setMaksimum({ value: criteria.maksimum, error: '' });
        setSkala({ value: criteria.skala, error: '' });
        setIsFixed({ value: criteria.is_fixed, error: '' });
        setStatus({ value: criteria.status, error: '' });
        setColumn({ value: criteria.booking_column, error: '' });
        setId({ value: criteria.id, error: '' });
    }

    const handleSubmit = () => {
        if (isUpdating) updateCriteria();
        else addCriteria();
    }

    const addCriteria = async () => {
        const token = (await Storage.get()).token;
        await fetch(`${BASE_URL}/criteria`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                judul: judul.value,
                minimum: minimum.value,
                maksimum: maksimum.value,
                skala: skala.value,
                is_fixed: isFixed.value,
                status: status.value,
                booking_column: column.value,
            })
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                setError(responseJSON.message)
                return;
            }
            await Swal.fire({
                title: 'Berhasil Tambah Kriteria',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                clearInput();
                fetchCriteria()
            });
        });
    }

    const updateCriteria = async () => {
        const token = (await Storage.get()).token;
        await fetch(`${BASE_URL}/criteria/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                judul: judul.value,
                minimum: minimum.value,
                maksimum: maksimum.value,
                skala: skala.value,
                is_fixed: isFixed.value,
                status: status.value,
                booking_column: column.value,
            })
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                setError(responseJSON.message)
                return;
            }
            await Swal.fire({
                title: 'Berhasil Update Kriteria',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                clearInput();
            });
        });
    }

    const deleteCriteria = async () => {
        setIsUpdating(false);
        const token = (await Storage.get()).token;
        await fetch(`${BASE_URL}/criteria/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                setError(responseJSON.message)
                return;
            }
            await Swal.fire({
                title: 'Berhasil Delete Kriteria',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                clearInput();
            });
        });
    }

    const clearInput = () => {
        setIsUpdating(false);
        setJudul({ value: '', error: '' });
        setMinimum({ value: 0, error: '' });
        setMaksimum({ value: 0, error: '' });
        setSkala({ value: 1, error: '' });
        setIsFixed({ value: false, error: '' });
        setStatus({ value: true, error: '' });
        setColumn({ value: '', error: '' });
        setId({ value: '', error: '' });
    }

    return (
        <View style={{ flex: 1, paddingVertical: 5 }}>
            <View style={{ marginBottom: 5 }}>
                <InputText
                    placeholder="Judul Criteria"
                    error={judul.error}
                    value={judul.value}
                    onChangeText={value => setJudul({value: value, error: ''})}
                    containerStyle={styles.input}
                />
                <View style={styles.row}>
                    <InputText
                        numeric
                        placeholder="Minimum"
                        error={minimum.error}
                        value={minimum.value}
                        onChangeText={value => setMinimum({value: value, error: ''})}
                        containerStyle={styles.input}
                    />
                    <View style={{ marginHorizontal: 2.5 }}/>
                    <InputText
                        numeric
                        placeholder="Maksimum"
                        error={maksimum.error}
                        value={maksimum.value}
                        onChangeText={value => setMaksimum({value: value, error: ''})}
                        containerStyle={styles.input}
                    />
                    <View style={{ marginHorizontal: 2.5 }}/>
                    <InputText
                        numeric
                        placeholder="Skala 1 - 10"
                        error={skala.error}
                        value={skala.value}
                        onChangeText={value => setSkala({value: value, error: ''})}
                        containerStyle={styles.input}
                    />
                </View>
                <View style={styles.row}>
                    <CheckBox
                        title='Persentase'
                        checked={isFixed.value}
                        onPress={() => setIsFixed({ value: !isFixed.value, error: '' })}
                    />
                    <CheckBox
                        title={status.value ? "Aktif" : "Tidak Aktif"}
                        checked={status.value}
                        onPress={() => setStatus({ value: !status.value, error: '' })}
                        textStyle={{ color: status.value ? COLORS.GREEN : COLORS.RED }}
                    />
                </View>
                <Text style={{ fontWeight: 600 }}>Referensi Data Booking</Text>
                <View style={styles.flexRow}>
                    {references.map((reference, i) => (
                        <CheckBox
                            key={i}
                            title={reference.label}
                            checked={column.value === reference.key}
                            onPress={() => setColumn({ value: reference.key, error: '' })}
                        />
                    ))}
                </View>
            </View>
            <View style={styles.buttonContainer}>
                <PrimaryButton
                    title={isUpdating ? 'UPDATE' : 'TAMBAH'}
                    fontSize={12}
                    onPress={handleSubmit}
                    containerStyle={{ minWidth: 80, marginRight: 5 }}
                />
                <PrimaryButton
                    title={'DELETE'}
                    fontSize={12}
                    onPress={deleteCriteria}
                    containerStyle={{ minWidth: 80, marginRight: 5 }}
                    color={COLORS.RED}
                />
                <SecondaryButton
                    title={'CLEAR'}
                    fontSize={12}
                    onPress={clearInput}
                    containerStyle={{ minWidth: 80 }}
                    color={COLORS.RED}
                />
            </View>
            <Table
                data={criteria}
                columns={columns}
                onSelect={onPressTable}
            />
        </View>
    )
}

const setStyles = mediaQuery => ({
    row: {
        flexDirection: 'row',
        marginBottom: 5
    },
    input: {
        flex: 1,
        marginTop: 5
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end',
        marginBottom: 20
    },
    flexRow: {
        flexDirection: mediaQuery.desktop ? 'row' : 'column',
        marginBottom: 5
    }
});