import COLORS from "./Colors";
const maxWidth = 800;
export default (mediaQuery) => ({
    statusButtonContainer: {
        flex: 1
    },
    smallInfo: {
        textAlign: 'right',
        fontSize: 12
    },
    inputContainer: {
        flex: 1
    },
    phone: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 5
    },
    image: {
        flex: 1,
        resizeMode: 'contain',
        minHeight: 200,
        marginBottom: 10
    },
    rowContainer: {
        flexDirection: 'row',
        alignItems: 'baseline',
        marginBottom: 10
    },
    divider: {
        borderColor: COLORS.GRAY,
        borderBottomWidth: 1,
    },
    tabButton: {
        flex: 1,
        padding: 4
    },
    tabButtonContainer: {
        flex: 1,
        marginHorizontal: 5
    },
    tabButtonText: {
        fontSize: 14,
        fontWeight: '500',
    },
    infoContainer: {
        margin: 10,
        paddingHorizontal: 20,
        paddingVertical: 10,
        maxWidth: mediaQuery.md ? maxWidth : undefined,
        backgroundColor: COLORS.WHITE,
        borderColor: COLORS.GRAY,
        borderRadius: 5,
        borderWidth: 1,
    },
    logContainer: {
        margin: 10,
        padding: 20,
        maxWidth: mediaQuery.md ? maxWidth : undefined,
        backgroundColor: COLORS.WHITE,
        borderColor: COLORS.GRAY,
        borderRadius: 5,
        borderWidth: 1,
    },
    actionContainer: {
        flexDirection: 'row',
        marginHorizontal: 10,
        paddingVertical: 10,
    },
    infoTitle: {
        fontSize: 16,
        fontWeight: '500',
        color: COLORS.BLACK,
        marginHorizontal: 10,
    },
    infoRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 20,
        marginHorizontal: mediaQuery.mobileL ? 0 : 10
    },
    infoBlock: {
        marginVertical: 20,
        marginHorizontal: mediaQuery.mobileL ? 0 : 10
    },
    infoLabel: {
        width: '40%',
        fontSize: mediaQuery.mobileM ? 12 : 14,
        fontWeight: '400',
        paddingHorizontal: 10
    },
    infoText: {
        fontSize: 14,
        fontWeight: '500',
        marginLeft: 10,
    },
    actionButtonContainer: {
        marginLeft: 5,
    },
    actionButton: {
        height: 36,
    },
    secondaryActionButton: {
        height: 36
    },
    buttonContainer: {
        marginHorizontal: 10,
        paddingVertical: 10,
        maxWidth: mediaQuery.md ? maxWidth : undefined,
        flexDirection: 'row',
        justifyContent: 'end'
    },
    buttonSmall: {
        fontSize: 10,
        fontWeight: '600',
        color: COLORS.WHITE,
        marginVertical: 5,
        marginHorizontal: 10,
        alignItems: 'center'
    },
});