import React from "react";
import { Text } from "react-native";
import { Switch } from "react-native-elements";
import Select2 from "react-native-select-two";
import { View } from "react-native-web";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { InputDropdown, InputText } from "../../components/Input";
import COLORS from "../../utilities/Colors";
import { BASE_URL, setMediaQuery } from "../../utilities/Constants";
import Storage from "../../utilities/Storage";

export default () => {
    const navigate = useNavigate();
    const mediaQuery = setMediaQuery();
    const styles = setStyles(mediaQuery);
    const { id } = useParams();
    const [name, setName] = React.useState({ value: '', error: '' });
    const [email, setEmail] = React.useState({ value: '', error: '' });
    const [phone, setPhone] = React.useState({ value: '', error: '' });
    const [role, setRole] = React.useState({ value: {}, error: '' });
    const [roles, setRoles] = React.useState([]);
    const [dealer, setDealer] = React.useState({ value: [], error: '' });
    const [dealers, setDealers] = React.useState([]);
    const [mainDealer, setMainDealer] = React.useState(false);

    const fetchRoles = async () => {
        const token = (await Storage.get()).token;
        fetch(`${BASE_URL}/users/roles`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            const roles = Object.keys(responseJSON.data).map(key => {
                return { value: +key, label: responseJSON.data[key] };
            });
            setRoles(roles);
        });
    };
    const fetchDealers = async (dealer) => {
        const token = (await Storage.get()).token;
        fetch(`${BASE_URL}/dealers/selection`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            setDealers(responseJSON.data.map((item) => ({
                id: item.value,
                name: item.label,
                checked: dealer && dealer.includes(item.value)
            })));
        });
    };
    const fetchUser = async () => {
        const token = (await Storage.get()).token;
        await fetch(`${BASE_URL}/users/${id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) navigate('/users');
            const user = responseJSON.user;
            const dealer = await JSON.parse(user.dealer_id);
            setEmail({ value: user.email, error: '' });
            setName({ value: user.name, error: '' });
            setPhone({ value: user.phone_number, error: '' });
            setRole({ value: { value: user.role_id, label: roles[user] }, error: '' });
            setDealer({ value: dealer, error: '' });
            await fetchDealers(dealer)
        });
    };
    const handleSubmit = async () => {
        if (dealer.value.length === 0) {
            await Swal.fire({
                title: 'Pilih Minimal 1 Dealer',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }
        const token = (await Storage.get()).token;
        await fetch(`${BASE_URL}/users/update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                name: name.value,
                email: email.value,
                phone_number: phone.value,
                role: role.value.value,
                dealer_id: dealer.value,
                id: id
            })
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                const message = responseJSON.message;
                setEmail({ value: email.value, error: message.email });
                setName({ value: name.value, error: message.email });
                setPhone({ value: phone.value, error: message.phone_number });
                setRole({ value: role.value, error: message.role });
                return;
            }
            await Swal.fire({
                title: 'Berhasil Update User',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        });
    }
    const resetPassword = async () => {
        const storage = await Storage.get();
        const token = storage.token;
        const id = storage.id;
        await fetch(`${BASE_URL}/users/password/reset`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                id: id,
                email: email.value
            })
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) {
                return;
            } else {
                await Swal.fire({
                    title: 'Berhasil Reset Password',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            }
        });
    }
    const handleDelete = async () => {
        const token = (await Storage.get()).token;
        await fetch(`${BASE_URL}/users/${id}/delete`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) return;
            navigate('/users');
        });
    }
    const getRole = () => {
        if (roles.length <= 0 || !role) return
        const role1 = roles.find(r => role.value.value === r.value);
        if (!role1) return;
        return role1.label;
    };

    React.useEffect(async () => {
        await fetchRoles();
        await fetchUser();
    }, []);

    React.useEffect(() => {
        if (!mainDealer) {
            setDealer({ value: [], error: "" });
        } else {
            setDealer({
                value: dealers.map(dealer => dealer.id),
                error: ""
            })
        }
    }, [mainDealer])
    return (
        <View style={{ flex: 1 }}>
            <View style={{ maxWidth: 640 }}>
                <Text style={{ fontSize: 18, fontWeight: '500', marginBottom: 15 }}>
                    User
                </Text>
                <InputDropdown
                    title={'Role User'}
                    data={roles}
                    value={getRole()}
                    onSelect={role => {
                        setRole({ value: role, error: '' })
                    }}
                />
                <Text style={{ fontSize: 12, fontWeight: '500', marginVertical: 5 }}>
                    Dealer
                </Text>
                <View style={{ flexDirection: 'row', marginVertical: 15, gap: 5 }}>
                    <Switch
                        value={mainDealer}
                        onValueChange={value => setMainDealer(value)}
                    />
                    <Text style={{ fontWeight: '400' }}>
                        Main Dealer
                    </Text>
                </View>
                {!mainDealer && (
                    <>
                        <Text style={{ fontSize: 12, fontWeight: '500', marginVertical: 5 }}>
                            Dealer
                        </Text>
                        <Select2
                            isSelectSingle={false}
                            showSearchBox={false}
                            style={styles.select2}
                            modalStyle={styles.select2Modal}
                            colorTheme={COLORS.RED}
                            cancelButtonText="Cancel"
                            selectButtonText="OK"
                            searchPlaceHolderText="Cari Dealer"
                            listEmptyTitle="Pilih Dealer"
                            popupTitle="Pilih Dealer"
                            title="Pilih Dealer"
                            data={dealers}
                            onSelect={value => setDealer({ value: value, error: '' })}
                            onRemoveItem={value => setDealer({ value: value, error: '' })}
                        />
                    </>
                )}
                <InputText
                    placeholder='Nama'
                    error={name.error}
                    value={name.value}
                    onChangeText={value => setName({ value: value, error: '' })}
                    containerStyle={styles.inputContainer}
                />
                <InputText
                    placeholder='Email'
                    error={email.error}
                    value={email.value}
                    onChangeText={value => setEmail({ value: value, error: '' })}
                    containerStyle={styles.inputContainer}
                />
                <InputText
                    numeric
                    placeholder='Nomor Telepon'
                    error={phone.error}
                    value={phone.value}
                    onChangeText={value => setPhone({ value: value, error: '' })}
                    containerStyle={styles.inputContainer}
                />
                <View style={styles.actionContainer}>
                    <PrimaryButton
                        title={'SAVE'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={() => handleSubmit()}
                        containerStyle={styles.buttonContainer}
                        buttonStyle={styles.button}
                    />
                    <SecondaryButton
                        title={'RESET PASSWORD'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={() => resetPassword()}
                        containerStyle={styles.buttonContainer}
                        buttonStyle={styles.button}
                    />
                    <SecondaryButton
                        title={'DELETE USER'}
                        fontSize={mediaQuery.mobileM ? 10 : 12}
                        onPress={() => handleDelete()}
                        containerStyle={styles.buttonContainer}
                        buttonStyle={styles.button}
                    />
                </View>
            </View>
        </View>
    )
}

const setStyles = (mediaQuery) => ({
    inputContainer: {
        marginTop: 10
    },
    actionContainer: {
        maxWidth: 720,
        paddingVertical: 10,
        flexDirection: 'row',
        justifyContent: 'end'
    },
    buttonContainer: {
        marginLeft: 5
    },
    button: {},
    select2: {
        borderRadius: 3, borderWidth: 2,
        borderColor: COLORS.GRAY,
        backgroundColor: COLORS.WHITE
    },
    select2Modal: {
        alignSelf: 'center',
        width: '100%', maxWidth: 640
    }
});