import React from "react";
import { Image, View } from "react-native-web";
import { BASE_URL } from "../../utilities/Constants";
import { Divider, Text } from "react-native-elements";
import Storage from "../../utilities/Storage";
import Swal from "sweetalert2";
import { InputText } from "../../components/Input";
import { IconButton, PrimaryButton, SecondaryButton } from "../../components/Button";
import COLORS from "../../utilities/Colors";
import { createColors } from "./Functions";

export default (props) => {
    const { unitID, styles, update, data, onFinish } = props;
    const [colors, setColors] = React.useState([]);
    const [saved, setSaved] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const fetchColors = async (signal) => {
        setLoading(true);
        const { token } = await Storage.get();
        const { success, colors } = await fetch(
            `${BASE_URL}/motors/${unitID}/colors`, {
                signal: signal,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        ).then(async response => await response.json());
        if (!success) return;
        const data = [];
        const metadata = { type: 'image/jpeg' };
        for (const color of colors) {
            const res = await fetch(`${BASE_URL}/${color.file}`);
            const blob = await res.blob();
            const fileName = color.file ? `${color.file.split('/')[1]}.jpg` : '';
            const file = new File([blob], fileName, metadata);
            data.push({
                name: color.name,
                value: '',
                file: file,
                kode: color.kode,
                img: `${BASE_URL}/${color.file}`
            });
        }
        setColors(data);
        setLoading(false);
    }
    const finish = async () => {
        for (const color of colors) {
            if (color.file.size / 1024 / 1024 > 2) {
                await Swal.fire({
                    title: 'File harus dibawah 2MB',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            }
            if (!color.file) {
                await Swal.fire({
                    title: 'Gagal input warna motor, ada file yang kurang',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            }
            if (!color.name) {
                await Swal.fire({
                    title: 'Gagal input warna motor, ada nama yang kosong',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            }
            if (!color.kode) {
                await Swal.fire({
                    title: 'Gagal input warna motor, ada kode yang kosong',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            }
        }
        if (update) {
            setLoading(true);
            await createColors(unitID, colors)
                .then(() => setLoading(false));
        } else {
            setSaved(true);
        }
        onFinish(colors);
    }

    const addColor = () => {
        setColors(prevState => ([...prevState, { name: '', kode: '', value: '', file: undefined, img: '' }]));
    }
    const deleteColor = (index) => {
        const newColors = colors.filter((_, i) => i !== index);
        setColors(newColors);
    }
    const setColorsItemText = (index, value, attr) => {
        let items = [...colors];
        let item = { ...items[index] };
        item[attr] = value;
        items[index] = item;
        setColors(items);
    }
    const setColorsItemImg = async (index, e) => {
        if (loading) return;
        let items = [...colors];
        let item = { ...items[index] };
        item.value = e.target.value;
        item.file = e.target.files[0];
        item.img = URL.createObjectURL(item.file)
        items[index] = item;
        setColors(items);
    }

    React.useEffect(async () => {
        if (!update) return;
        const controller = new AbortController();
        const signal = controller.signal;
        await fetchColors(signal);
        return () => controller.abort();
    }, []);

    React.useEffect(() => {
        setColors(data);
    }, [data]);


    return (
        <View styles={{ flex: 1 }}>
            <View style={{ ...styles.infoRow, justifyContent: 'space-between' }}>
                <Text style={styles.infoTitle}>
                    Warna Unit
                </Text>
                <Text style={styles.smallInfo}>
                    * save dahulu sebelum berpindah tab
                </Text>
            </View>
            {colors.map((color, i) => (
                <View key={i}>
                    <View style={{ ...styles.infoRow, justifyContent: 'space-between' }}>
                        <View style={{ flex: 1, marginRight: 10, paddingHorizontal: 10 }}>
                            <View style={styles.infoRow}>
                                <Image
                                    source={color.img}
                                    style={{
                                        resizeMode: 'contain',
                                        width: '100%',
                                        minHeight: color.img ? 200 : 0,
                                        marginBottom: 20
                                    }}/>
                                <IconButton
                                    icon="trash"
                                    iconType="font-awesome"
                                    iconColor={COLORS.RED}
                                    iconSize={28}
                                    onPress={() => deleteColor(i)}
                                />
                            </View>
                            <View style={{ marginBottom: 20 }}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={e => setColorsItemImg(i, e)}/>
                            </View>
                            <InputText
                                placeholder="Nama"
                                value={color.name}
                                onChangeText={text => setColorsItemText(i, text, 'name')}
                                containerStyle={{ marginBottom: 5 }}
                            />
                            <InputText
                                placeholder="Kode"
                                value={color.kode}
                                onChangeText={text => setColorsItemText(i, text, 'kode')}
                            />
                        </View>
                    </View>
                    <Divider style={styles.divider}/>
                </View>
            ))}
            {saved ?
                <Text style={{ fontSize: 12, color: COLORS.GREEN, paddingHorizontal: 10 }}>
                    Data tersimpan
                </Text> : <View/>
            }
            <View style={styles.actionContainer}>
                <PrimaryButton
                    title={'SAVE'}
                    fontSize={12}
                    onPress={finish}
                    loading={loading}
                    containerStyle={{ flex: 1, marginRight: 5 }}
                    buttonStyle={styles.actionButton}
                />
                <SecondaryButton
                    icon="plus"
                    iconType="ant-design"
                    iconSize={14}
                    iconColor={COLORS.BLUE}
                    onPress={addColor}
                    buttonStyle={styles.secondaryActionButton}
                />
            </View>
        </View>
    )
}
