import React from "react";
import { StyleSheet, View } from "react-native-web";
import { Text } from "react-native-elements";

export default props => {
    return (
        <View style={styles.container}>
            <Text style={{fontSize: 24, fontWeight: 'bold'}}>
                {`${props.code}, ${STATUS[props.code]}`}
            </Text>
            <Text style={{fontSize: 2}}>
                {`You Shall Not Pass`}
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const STATUS = {
    '404': 'PAGE NOT FOUND',
    '403': 'FORBIDDEN ACCESS',
    '500': 'SERVICE UNAVAILABLE'
}