import React from "react";
import { useNavigate } from "react-router";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { BASE_URL } from "../utilities/Constants";
import Storage from "../utilities/Storage";
import Loading from "../pages/utils/Loading";

export default () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [token, setToken] = React.useState(null);

    const authorize = async () => {
        const token = (await Storage.get()).token;
        fetch(`${BASE_URL}/authorize`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(async response => {
            const responseJSON = await response.json();
            if (!responseJSON.success) navigate('/login');
        });
    }

    React.useEffect(async () => {
        if (token) return;
        setToken((await Storage.get()).token);
        setLoading(false);
        await authorize();
    });

    if (loading) return <Loading/>;
    if (!token) return <Navigate to="/login" state={{ from: location }}/>;
    return <Outlet/>;
}