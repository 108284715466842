import React from "react";
import { Outlet } from "react-router-dom";
import { ROLES } from "../utilities/Constants";
import Storage from "../utilities/Storage";
import { Error, Loading } from "../pages";

export default () => {
    const [loading, setLoading] = React.useState(true);
    const [role, setRole] = React.useState(null);

    React.useEffect(async () => {
        if (role) return;
        setRole((await Storage.get()).role);
        setLoading(false);
    });

    if (loading) return <Loading/>;
    if (role >= ROLES.ALL || (role >= ROLES.ADMIN[0] && role < ROLES.ADMIN[1])) {
        return <Outlet/>;
    }
    return <Error code={403}/>;
}