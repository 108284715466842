import React from "react";
import COLORS from "../utilities/Colors";
import { Button } from "react-native-elements";

export const PrimaryButton = props => (
    <Button
        title={props.title}
        iconPosition={props.iconPosition ?? 'left'}
        icon={props.icon ? {
            name: props.icon,
            type: props.iconType,
            color: COLORS.WHITE,
            size: props.iconSize
        } : undefined}
        buttonStyle={{
            backgroundColor: props.color ?? COLORS.BLUE,
            borderColor: props.color ?? COLORS.BLUE,
            borderWidth: 2,
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 5,
            ...props.buttonStyle
        }}
        containerStyle={props.containerStyle ?? {}}
        titleStyle={{
            color: COLORS.WHITE,
            fontSize: props.fontSize ?? 16,
            fontWeight: "500",
            ...props.titleStyle
        }}
        onPress={props.onPress}
        loading={props.loading}
    />
)

export const SecondaryButton = props => (
    <Button
        title={props.title}
        iconPosition={props.iconPosition ?? 'left'}
        icon={props.icon ? {
            name: props.icon,
            type: props.iconType,
            color: props.iconColor ?? COLORS.WHITE,
            size: props.iconSize
        } : undefined}
        buttonStyle={{
            backgroundColor: 'transparent',
            borderColor: props.color ?? COLORS.BLUE,
            borderWidth: 2,
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 5,
            ...props.buttonStyle
        }}
        containerStyle={props.containerStyle ?? {}}
        titleStyle={{
            color: props.color ?? COLORS.BLUE,
            fontSize: props.fontSize ?? 16,
            fontWeight: "500",
            ...props.titleStyle
        }}
        onPress={props.onPress}
    />
)


export const IconButton = props => (
    <Button
        title={props.title}
        iconPosition={props.iconPosition ?? 'left'}
        icon={{
            name: props.icon,
            type: props.iconType,
            color: props.iconColor ?? COLORS.BLUE,
            size: props.iconSize
        }}
        buttonStyle={{
            backgroundColor: props.color ?? 'transparent',
            borderColor: 'transparent',
            borderWidth: 0,
            alignSelf: 'flex-start',
            padding: 0
        }}
        containerStyle={{
            ...props.containerStyle
        }}
        titleStyle={{
            color: props.fontColor ?? COLORS.WHITE,
            fontSize: props.fontSize ?? 16,
            ...props.titleStyle
        }}
        onPress={props.onPress}
    />
)